import React from 'react';
import { Button } from '../../../../ui/Button';
import styles from '../WhisperCard.module.css';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/**
 * WhisperCardOpenButton component.
 *
 * @param {number} rewardCount - The count of rewards.
 * @param {function} onClick - Function to handle click event.
 * @returns {JSX.Element} WhisperCardOpenButton component.
 */
export const WhisperCardOpenButton = ({ rewardCount, onClick }) => {
	const { t } = useTranslation();
	// Render WhisperCardOpenButton component
	const whispercardOpenButtonCoinClasses = classNames(styles.whispercardOpenButtonCoin, 'bg-whisper-coin-icon');
	return (
		<Button styleClasses={styles.whispercardOpenButton} onClick={onClick}>
			<div className={styles.whispercardOpenButtonBox}>
				<span>{t('main.card.readButton')}</span>
				<div className={styles.whispercardOpenButtonRewards}>
					{rewardCount} <div className={whispercardOpenButtonCoinClasses} />
				</div>
			</div>
		</Button>
	);
};
