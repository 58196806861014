// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SendNewWhisper_sentWhisperContainer__s3Am1 {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 120%;
    overflow-y: auto
}

.SendNewWhisper_sentWhisperTitle__I97xT {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 2rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.SendNewWhisper_sentWhisperContent__UhBoa {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 296px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem
}

@media (min-width: 390px) {

    .SendNewWhisper_sentWhisperContent__UhBoa {
        width: 20rem
    }
}

@media (min-width: 430px) {

    .SendNewWhisper_sentWhisperContent__UhBoa {
        width: 350px
    }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/SendNewWhisper/SendNewWhisper.module.css"],"names":[],"mappings":"AACI;IAAA,kBAAgC;IAAhC,aAAgC;IAAhC,sBAAgC;IAAhC,WAAgC;IAChC,YAAY;IACZ;AAFgC;;AAMhC;IAAA,kBAA4D;IAA5D,8BAA4D;IAA5D,eAA4D;IAA5D,gBAA4D;IAA5D,oBAA4D;IAA5D;AAA4D;;AAI5D;IAAA,eAAkG;IAAlG,kBAAkG;IAAlG,iBAAkG;IAAlG,kBAAkG;IAAlG,aAAkG;IAAlG,YAAkG;IAAlG,sBAAkG;IAAlG,mBAAkG;IAAlG,uBAAkG;IAAlG;AAAkG;;AAAlG;;IAAA;QAAA;IAAkG;AAAA;;AAAlG;;IAAA;QAAA;IAAkG;AAAA","sourcesContent":[".sentWhisperContainer {\n    @apply flex flex-col  mt-6 gap-6;\n    height: 120%;\n    overflow-y: auto;\n}\n\n.sentWhisperTitle {\n    @apply font-sans font-bold text-3.5xl text-white text-center;\n}\n\n.sentWhisperContent {\n    @apply flex flex-col items-center justify-center gap-3 w-[296px] my-0 mx-auto lg:w-80 xl:w-[350px];\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentWhisperContainer": `SendNewWhisper_sentWhisperContainer__s3Am1`,
	"sentWhisperTitle": `SendNewWhisper_sentWhisperTitle__I97xT`,
	"sentWhisperContent": `SendNewWhisper_sentWhisperContent__UhBoa`
};
export default ___CSS_LOADER_EXPORT___;
