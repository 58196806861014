const NegativeCircle = ({ width = 24, height = 24 }) => (
	<svg
		width={width}
		height={height}
		style={{ minHeight: height, minWidth: width }}
		viewBox='0 0 80 80'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M29.114 29.114C29.9744 28.2537 31.3693 28.2537 32.2297 29.114L40 36.8843L47.7703 29.114C48.6307 28.2537 50.0256 28.2537 50.886 29.114C51.7463 29.9744 51.7463 31.3693 50.886 32.2297L43.1157 40L50.886 47.7703C51.7463 48.6307 51.7463 50.0256 50.886 50.886C50.0256 51.7463 48.6307 51.7463 47.7703 50.886L40 43.1157L32.2297 50.886C31.3693 51.7463 29.9744 51.7463 29.114 50.886C28.2537 50.0256 28.2537 48.6307 29.114 47.7703L36.8843 40L29.114 32.2297C28.2537 31.3693 28.2537 29.9744 29.114 29.114Z'
			fill='#FF3131'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M40 12.5C24.8122 12.5 12.5 24.8122 12.5 40C12.5 55.1878 24.8122 67.5 40 67.5C55.1878 67.5 67.5 55.1878 67.5 40C67.5 24.8122 55.1878 12.5 40 12.5ZM7.5 40C7.5 22.0507 22.0507 7.5 40 7.5C57.9493 7.5 72.5 22.0507 72.5 40C72.5 57.9493 57.9493 72.5 40 72.5C22.0507 72.5 7.5 57.9493 7.5 40Z'
			fill='#FF3131'
		/>
	</svg>
);

export default NegativeCircle;
