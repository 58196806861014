import React from 'react';
import { WHISPER_CARD_CONFIG } from '../WhisperCardConfig';
import styles from '../WhisperCard.module.css';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/**
 * Component for rendering reactions in the Whisper Card.
 * @param {Object} react - React state for reactions.
 * @param onReactClick
 * @returns {JSX.Element} React element with reactions.
 */
export const WhisperCardReactions = ({ cardData, onReactClick }) => {
	// Destructuring ASSETS and STRINGS from WHISPER_CARD_CONFIG
	const {
		ASSETS: { SMILES },
		STRINGS: { REACTIONS_TITLE }
	} = WHISPER_CARD_CONFIG;

	const { t } = useTranslation();

	const whisperCardReactionButton = smileId => classNames(styles.whispercardReactionButton, `bg-${smileId}`);
	const whisperCardReactionBoxWithSmile = smileId =>
		classNames(styles.whispercardReactionBox, `bg-${smileId} bg-no-repeat bg-center`);

	const reactClickHandler = e => {
		const react = e.target.value;

		onReactClick(react, cardData);
	};

	return (
		<div className={styles.whispercardReactionsBox}>
			<h6 className={styles.whispercardReactionsTitle}>
				{!!cardData.react ? t(REACTIONS_TITLE.REACT) : t(REACTIONS_TITLE.UNREACT)}
			</h6>
			<ul className={styles.whispercardReactionsList}>
				{!!cardData.react ? (
					<div className={whisperCardReactionBoxWithSmile(cardData.react)} />
				) : (
					SMILES.map(({ id }) => (
						<div className={styles.whispercardReactionBox} key={id}>
							<button className={whisperCardReactionButton(id)} value={id} onClick={reactClickHandler} />
						</div>
					))
				)}
			</ul>
		</div>
	);
};
