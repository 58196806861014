import React, { useState } from 'react';
import styles from './SegmentsController.module.css';
import { Button } from '../Button';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/**
 * Renders a segments controller component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.config - The configuration for the segments.
 * @param {Function} props.setActiveTab - The function to set the active tab.
 * @param {Object} props.containerRef - The reference to the container element.
 * @return {JSX.Element} The rendered segments controller component.
 */
export const SegmentsController = ({ config, setActiveTab, containerRef }) => {
	const [activeSegment, setActiveSegment] = useState(config[0].id);
	const { t } = useTranslation();
	const handleSegmentClick = (id) => {
		setActiveSegment(id);
		setActiveTab(id);
		containerRef.current.scrollTo(0, 0);
	};

	return (
		<div className={styles.segmentsControllerContainer} role='group'>
			{config.map(item => {
				const segmentButton = classNames(
					styles.segmentsButtonItem,
					item.id === activeSegment && styles.segmentsButtonItemActive
				);
				const segmentButtonText = classNames(
					styles.segmentsButtonItemText,
					item.id === activeSegment ? styles.segmentsButtonItemTextActive : styles.segmentsButtonItemInActive
				);
				return (
					<Button key={item.id} styleClasses={segmentButton} onClick={() => handleSegmentClick(item.id)}>
						<div className={segmentButtonText}>{t(item.text)}</div>
					</Button>
				);
			})}
		</div>
	);
};
