/**
 * Renders a spinner SVG component with an optional fill color.
 *
 * @param {Object} props - The props object.
 * @param {string} [props.fill] - The fill color of the spinner. Defaults to "#fff" if not provided.
 * @return {JSX.Element} The spinner SVG component.
 */
export const Spinner = ({ fill, width = 24, height = 24, className = '' }) => {
	return (
		<svg
			className={className}
			width='24'
			height='24'
			fill='none'
			style={{ minWidth: width, minHeight: height }}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12 2.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V3a.75.75 0 0 1 .75-.75ZM17.25 12a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5h-3a.75.75 0 0 1-.75-.75ZM16.773 15.712a.75.75 0 0 0-1.06 1.061l2.12 2.121a.75.75 0 1 0 1.061-1.06l-2.121-2.122ZM12 17.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75ZM8.287 16.773a.75.75 0 0 0-1.06-1.06l-2.122 2.12a.75.75 0 1 0 1.061 1.061l2.121-2.121ZM2.25 12a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1-.75-.75ZM6.166 5.105a.75.75 0 0 0-1.06 1.061l2.12 2.121a.75.75 0 1 0 1.061-1.06l-2.12-2.122Z'
				fill={fill || '#fff'}
			/>
		</svg>
	);
};
