const XIcon = ({ width, height }) => (
	<svg
		width={width}
		height={height}
		style={{ minHeight: height, minWidth: width }}
		viewBox='0 0 40 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<rect width='40' height='40' rx='8' fill='black' />
		<path
			d='M10.0488 11L17.7713 20.9293L10 29H11.75L18.5518 21.9319L24.0488 29H30L21.8445 18.5137L29.0762 11H27.3293L21.064 17.5081L16.003 11H10.0488ZM12.622 12.2371H15.3567L27.4299 27.7599H24.6951L12.622 12.2371Z'
			fill='white'
		/>
	</svg>
);
export default XIcon;
