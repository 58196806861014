import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './SendNewWhisper.module.css';
import { SearchUserInput } from './SearchUserInput';
import { GeneralTitle } from '../../ui/GeneralTitle';
import { NewWhisperEnergyBar } from './NewWhisperEnergyBar';
import { NewWhisperField } from './NewWhisperField';
import { Modal } from '../../ui/Modal';
import { Button } from '../../ui/Button';
import { SEND_NEW_WHISPER_CONFIG } from './SendNewWhisperConfig';
import { NewWhisperIcon } from '../../../assets/svg/NewWhisperIcon';
import { useTranslation } from 'react-i18next';
import { getUserByUsername,sentNewMessageData } from '../../../utils/api';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';

/**
 * Renders a component for sending a new whisper.
 *
 * @return {JSX.Element} The rendered component.
 */
export const SendNewWhisper = () => {
	const { STRINGS } = SEND_NEW_WHISPER_CONFIG;
	const [searchUserState, setSearchUserState] = useState('');
	const [sentModalIsOpen, setSentModalIsOpen] = useState(false);
	const [formValues, setFormValues] = useState({ username: '', whisper: '', isAnonymous: true });
	const whisperFieldContainerRef = useRef(null);
	const { t } = useTranslation();
	const { currentUser } = useContext(CurrentUserContext)
	const ENERGY_CONFIG = {
		availableEnergy: currentUser.energy,
		allEnergy: 4,
		requiredEnergy: 3
	};

	const openSentModal = () => setSentModalIsOpen(true);
	const closeSentModal = () => setSentModalIsOpen(false);

	const sendButtonClickHandler = () => {
		const sentNewMessage = async () => {
			try {
				const data = await sentNewMessageData(formValues.username, formValues.whisper, formValues.isAnonymous.toString());

				if (data) {
					openSentModal();
				}
			} catch (error) {
				console.log(error);
			}
		};

		sentNewMessage();
	};

	useEffect(() => {
		const getUserRecipient = async () => {
			setSearchUserState('pending');
			try {
				const data = await getUserByUsername(formValues.username);

				if (data) {
					setSearchUserState('success');
				} else {
					setSearchUserState('notFound');
				}
			} catch (error) {
				setSearchUserState('error')
			}
		};

		if (formValues.username) {
			getUserRecipient()
		} else {
			setSearchUserState('');
		}

	}, [formValues.username]);

	const clearFormValues = () => {
		setFormValues({ username: '', whisper: '', isAnonymous: true });
	};

	const handlerClickModalButton = () => {
		closeSentModal();
		clearFormValues();
	};

	return (
		<>
			<div className={style.sentWhisperContainer} ref={whisperFieldContainerRef}>
				<GeneralTitle>{t('newWhispers-page.title')}</GeneralTitle>
				<div className={style.sentWhisperContent}>
					<SearchUserInput
						userState={searchUserState}
						value={formValues.username}
						setValue={value => setFormValues({ ...formValues, username: value })}
					/>
					<NewWhisperField
						whisperFieldContainerRef={whisperFieldContainerRef}
						fieldValues={formValues}
						setFieldValues={setFormValues}
					/>
					<NewWhisperEnergyBar
						disablesState={searchUserState !== 'success' || ENERGY_CONFIG.requiredEnergy > ENERGY_CONFIG.availableEnergy }
						availableEnergy={ENERGY_CONFIG.availableEnergy}
						allEnergy={ENERGY_CONFIG.allEnergy}
						requiredEnergy={ENERGY_CONFIG.requiredEnergy}
						onButtonCLick={sendButtonClickHandler}
					/>
				</div>
			</div>
			<Modal
				isOpen={sentModalIsOpen}
				customStyles='w-[294px] text-white p-9 flex flex-col items-center text-center gap-5'
				onClose={closeSentModal}>
				<NewWhisperIcon fill='var(--color-successGreen)' width={80} height={80} />
				<div className='flex flex-col'>
					<h3 className={'text-bold text-4sm leading-6'}>{STRINGS.WHISPER_SENT_MODAL.TITLE}</h3>
					<p className={'text-grey1 text-2sm leading-5'}>{STRINGS.WHISPER_SENT_MODAL.INFO}</p>
				</div>
				<Button styleClasses='py-3.5 px-10 text-medium text-2sm leading-5' onClick={handlerClickModalButton}>
					{STRINGS.WHISPER_SENT_MODAL.BUTTON_LABEL}
				</Button>
			</Modal>
		</>
	);
};
