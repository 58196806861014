export const ERRORS_CONFIG = {
	SERVER: {
		DEFAULT: 'Ошибка получения данных с сервера',
		400: 'Ошибка отправляемых данных на сервер или запроса',
		401: 'Ошибка авторизации',
		404: 'Ресурс не найден',
		500: 'Ошибка сервера',
		UNKNOWN: 'Неизвестный статус код ошибки'
	}
};

export const BASE_URL = '';
export const AUTH_TOKEN = '';