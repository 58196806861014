import React from 'react';
import styles from './UserInformation.module.css';
import { useTranslation } from 'react-i18next';

/**
 * Renders the user information component.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.userName - The name of the user.
 * @param {string} props.notifications - The notifications for the user.
 * @return {JSX.Element} The rendered user information component.
 */
export const UserInformation = ({ userName, notifications }) => {
	const { t } = useTranslation();
	return (
		<div className={styles.userInformation}>
			<h6 className={styles.userInformationTitle}>{userName}</h6>
			<p className={styles.userInformationText}>{t(notifications, { count: 1 })}</p>
		</div>
	);
};
