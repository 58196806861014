// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Main_main__trVLD {

    position: relative;

    margin: auto;

    max-width: 430px
}

.Main_mainIntro__umUVB {

    padding-top: 1.5rem;

    padding-bottom: 1.5rem;

    padding-left: 3rem;

    padding-right: 3rem
}

@media (max-width: 320px) {

    .Main_mainIntro__umUVB {

        padding-left: 1rem;

        padding-right: 1rem
    }
}

.Main_mainIntroBox__IbDSa {

    margin: auto;

    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center;

    gap: 0.5rem
}

.Main_mainIntroTitle__yIKKQ {

    width: fit-content;

    text-align: center;

    font-size: 1.875rem;

    font-weight: 700;

    line-height: 2rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.Main_mainIntroSubtitle__poQGP {

    width: fit-content;

    text-align: center;

    font-size: 1.0625rem;

    font-weight: 400;

    line-height: 1.375rem;

    --tw-text-opacity: 1;

    color: rgb(154 162 197 / var(--tw-text-opacity))
}

.Main_mainCardLists__afOWb {

    margin-bottom: 10rem;

    display: flex;

    flex-direction: column;

    gap: 1.75rem
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Main/Main.module.css"],"names":[],"mappings":"AACC;;IAAA,kBAAsC;;IAAtC,YAAsC;;IAAtC;AAAsC;;AAItC;;IAAA,mBAA2B;;IAA3B,sBAA2B;;IAA3B,kBAA2B;;IAA3B;AAA2B;;AAA3B;;IAAA;;QAAA,kBAA2B;;QAA3B;IAA2B;AAAA;;AAI3B;;IAAA,YAA6D;;IAA7D,aAA6D;;IAA7D,sBAA6D;;IAA7D,mBAA6D;;IAA7D,uBAA6D;;IAA7D;AAA6D;;AAI7D;;IAAA,kBAAgE;;IAAhE,kBAAgE;;IAAhE,mBAAgE;;IAAhE,gBAAgE;;IAAhE,iBAAgE;;IAAhE,oBAAgE;;IAAhE;AAAgE;;AAIhE;;IAAA,kBAAoE;;IAApE,kBAAoE;;IAApE,oBAAoE;;IAApE,gBAAoE;;IAApE,qBAAoE;;IAApE,oBAAoE;;IAApE;AAAoE;;AAIpE;;IAAA,oBAAiC;;IAAjC,aAAiC;;IAAjC,sBAAiC;;IAAjC;AAAiC","sourcesContent":[".main {\n\t@apply relative max-w-screen-xl m-auto;\n}\n\n.mainIntro {\n\t@apply py-6 px-12 m-sx:px-4;\n}\n\n.mainIntroBox {\n\t@apply flex flex-col items-center justify-center gap-2 m-auto;\n}\n\n.mainIntroTitle {\n\t@apply w-fit font-bold text-center text-white text-3xl leading-8;\n}\n\n.mainIntroSubtitle {\n\t@apply w-fit text-3sm text-center text-grey1 leading-5-5 font-normal;\n}\n\n.mainCardLists {\n\t@apply flex flex-col gap-7 mb-40 ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Main_main__trVLD`,
	"mainIntro": `Main_mainIntro__umUVB`,
	"mainIntroBox": `Main_mainIntroBox__IbDSa`,
	"mainIntroTitle": `Main_mainIntroTitle__yIKKQ`,
	"mainIntroSubtitle": `Main_mainIntroSubtitle__poQGP`,
	"mainCardLists": `Main_mainCardLists__afOWb`
};
export default ___CSS_LOADER_EXPORT___;
