/**
 * Renders an SVG icon of Friends.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.fill - The color to fill the icon with.
 * @return {JSX.Element} The rendered SVG icon.
 */
export const FriendsIcon = ({ fill }) => {
	return (
		<svg width='36' height='36' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.75 10a6.875 6.875 0 1 0 0 13.75 6.875 6.875 0 0 0 0-13.75Zm-9.375 6.875a9.375 9.375 0 1 1 18.75 0 9.375 9.375 0 0 1-18.75 0ZM27.53 10.077a6.877 6.877 0 0 0-2.909.178 1.25 1.25 0 1 1-.678-2.406 9.376 9.376 0 1 1 2.544 18.4 1.25 1.25 0 0 1 0-2.5 6.876 6.876 0 0 0 1.043-13.672Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.75 26.25a12.504 12.504 0 0 0-10.227 5.312 1.25 1.25 0 1 1-2.045-1.438 15.004 15.004 0 0 1 24.545 0 1.25 1.25 0 0 1-2.045 1.437A12.503 12.503 0 0 0 13.75 26.25ZM32.25 27.655a12.484 12.484 0 0 0-5.762-1.405 1.25 1.25 0 0 1-.002-2.5 14.983 14.983 0 0 1 12.274 6.374 1.25 1.25 0 1 1-2.046 1.437 12.484 12.484 0 0 0-4.464-3.906Z'
				fill={fill}
			/>
		</svg>
	);
};
