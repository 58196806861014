import React from 'react';
import classNames from 'classnames';

/**
 * Renders a general title component with optional styling classes.
 *
 * @param {Object} props - The properties for the component.
 * @param {ReactNode} props.children - The content of the title.
 * @param {string} [props.styleClasses] - Additional CSS classes for styling.
 * @return {ReactElement} The rendered general title component.
 */
export const GeneralTitle = ({ children, styleClasses }) => {
	const GeneralTitleClasses = classNames('general-title', styleClasses);
	return (
		<h1 className={GeneralTitleClasses}>
			{children}
		</h1>
	);
};