/**
 * Returns the mobile operating system based on the user agent string.
 *
 * @return {string} The mobile operating system, or 'unknown' if it cannot be determined.
 */
export function getMobileOperatingSystem() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Windows Phone must come first because its UA also contains "Android"
	if (/windows phone/i.test(userAgent)) {
		return 'Windows Phone';
	}

	if (/android/i.test(userAgent)) {
		return 'Android';
	}

	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return 'iOS';
	}

	return 'unknown';
}