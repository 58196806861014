import React, { useEffect, useState } from 'react';
import styles from './ChallengesCard.module.css';
import { Card } from '../../../ui/Card';
import { ChallengesCardInfo } from './ChallengesCardInfo';
import { CHALLENGES_CONFIG } from '../ChallengesConfig';
import classNames from 'classnames';
import { ChallengesCardButton } from './ChallengesCardButton';
import { ChallengesSocialAppIcon } from '../ChallengesSocialAppIcon';
/**
 * Represents a Challenges Card component.
 * @param {Object} props - The props for the Challenges Card component.
 */
export const ChallengesCard = ({ data, dataType, onCardClick = () => {} }) => {
	// Destructuring variables from props and CHALLENGES_CONFIG
	const { CARD_IMG_SIZE } = CHALLENGES_CONFIG;
	const [challengesCardStatus, setChallengesCardStatus] = useState({ isComplete: false, isLoaded: true });
	const { id, appId, options, typeId } = data;

	// Effect hook to update challengesCardStatus with options
	useEffect(() => {
		setChallengesCardStatus(prevStatus => ({ ...prevStatus, ...options }));
	}, []);

	// Dynamically set classes based on challengesCardStatus
	const OpacityBoxClasses = classNames('flex gap-3', challengesCardStatus.isComplete && 'opacity-35');

	// Click handler for the card button
	const handleButtonClick = () => {
		const timeout = setTimeout(() => onCardClick(data), 200);

		return () => clearTimeout(timeout);
	};

	// Render the Challenges Card component
	return (
		<Card customClasses={styles.challengesCard}>
			<div className={styles.challengesCardBox}>
				<div className={OpacityBoxClasses}>
					{/* Render ChallengesSocialAppIcon with dynamic key and props */}
					<ChallengesSocialAppIcon key={`${appId}-app-icon_${id}`} appId={appId} imageSize={CARD_IMG_SIZE} />
					{/* Render ChallengesCardInfo component */}
					<ChallengesCardInfo data={data} isComplete={challengesCardStatus.isComplete} />
				</div>
				{/* Render ChallengesCardButton component */}
				<ChallengesCardButton
					challengesCardStatus={challengesCardStatus}
					setChallengesCardStatus={setChallengesCardStatus}
					dataType={dataType}
					onClick={handleButtonClick}
				/>
			</div>
		</Card>
	);
};
