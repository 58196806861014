import React, { useEffect, useState } from 'react';
import styles from './SearchUserInput.module.css';
import { DarkField } from '../../../ui/DarkField';
import { Button } from '../../../ui/Button';
import classNames from 'classnames';
import { CheckCircle } from '../../../../assets/svg/CheckCircle';
import { Spinner } from '../../../../assets/svg/Spinner';
import { useTranslation } from 'react-i18next';

export const SearchUserInput = ({ userState, value, setValue }) => {
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState(value);

	const handleInputChange = e => {
		const newValue = e.target.value;
		setInputValue(newValue);
	};

	// debounce for input
	useEffect(() => {
		const handler = setTimeout(() => {
			setValue(inputValue);
		}, 300);

		return () => {
			clearTimeout(handler);
		};
	}, [inputValue]);

	const searchUserButton = () => {
		if (userState === 'pending') {
			return (
				<div className={styles.searchUserInputSpinner}>
					<Spinner fill={'var(--color-grey2)'} />
				</div>
			);
		}
		if (userState === 'success') {
			return (
				<div className={styles.searchUserInputCheckCircle}>
					<CheckCircle />
				</div>
			);
		}
		if (userState === 'notFound') {
			return (
				<Button styleClasses={styles.searchUserInputInviteButton}>
					<span className={styles.searchUserInputInviteButtonText}>{t('newWhispers-page.buttons.inviteButton')}</span>
				</Button>
			);
		}
		return null;
	};

	const searchUserInputContainer = classNames(
		styles.searchUserInputContainer,
		userState === 'error' && styles.searchUserInputContainerError
	);
	return (
		<DarkField styleClasses={searchUserInputContainer}>
			<div className={styles.searchUserInputMainContent}>
				<input
					className={styles.searchUserInput}
					id={'username'}
					type={'text'}
					value={inputValue}
					onChange={handleInputChange}
					placeholder={t('newWhispers-page.forms.searchUserInput.placeholder')}
				/>
				{searchUserButton()}
			</div>
			{userState === 'notFound' && (
				<p className={styles.searchUserInputInviteText}>{t('newWhispers-page.forms.searchUserInput.userNotFoundInvite')}</p>
			)}
			{userState === 'error' && (
				<span className={styles.searchUserInputErrorText}>{t('newWhispers-page.forms.searchUserInput.userNotFoundError')}</span>
			)}
		</DarkField>
	);
};
