import React from 'react';
import classNames from 'classnames';

export const Button = ({ disabled = false, children, onClick = () => {}, styleClasses }) => {
	const ButtonClasses = classNames('blue-button', styleClasses);

	const handleClick = e => {
		e.stopPropagation();
		onClick(e);
	};

	return (
		<button disabled={disabled} className={ButtonClasses} onClick={handleClick}>
			{children}
		</button>
	);
};
