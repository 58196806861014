import React, { useEffect, useState } from 'react';
import { TEST_DATA, MAIN_CONFIG } from './MainConfig';
import styles from './Main.module.css';
import { CardList } from '../../ui/CardList';
import { useTranslation } from 'react-i18next';

/**
 * Main component renders the main content of the application.
 * @param {} props - No props required currently.
 * @returns JSX element
 */
export const Main = ({}) => {
	// Destructuring values from MAIN_CONFIG
	const {
		STRINGS: { TITLE, SUBTITLE },
		LIST_DEF_TYPE
	} = MAIN_CONFIG;
	const { t } = useTranslation();

	const [whispersData, setWhispersData] = useState(LIST_DEF_TYPE);
	// Destructuring values from MAIN_CONFIG

	useEffect(() => {
		setWhispersData(TEST_DATA);
	}, []);

	const reactClickHandler = (react, cardData) => {
		const { id: cardId } = cardData;

		const updatedUnreactedList = whispersData.lists[0]?.items.filter(({ id }) => id !== cardId);
		const updatedReactedList = [
			{ ...cardData, react, options: { isOpened: true, isCollapsed: true } },
			...whispersData.lists[1]?.items
		];

		setWhispersData(prevWhispersData => ({
			...prevWhispersData,
			lists: [
				{ title: prevWhispersData.lists[0]?.title, items: updatedUnreactedList },
				{ title: prevWhispersData.lists[1]?.title, items: updatedReactedList }
			]
		}));
	};

	return (
		<>
			{/* Main container */}
			<div className={styles.main}>
				{/* Main introduction section */}
				<div className={styles.mainIntro}>
					<div className={styles.mainIntroBox}>
						{/* Main title */}
						<h1 className={styles.mainIntroTitle}>{t('main.intro.title') + whispersData.username}</h1>
						{/* Main subtitle */}
						<p className={styles.mainIntroSubtitle}>{t('main.intro.subtitle', { count: whispersData.lists[0]?.items.length })}</p>
					</div>
				</div>
				{/* Main card lists */}
				<div className={styles.mainCardLists}>
					{whispersData.lists?.map(({ title, items }, index) => (
						<CardList
							key={index}
							list={items}
							CardElement={whispersData.CardElement}
							title={title}
							onReactClick={reactClickHandler}
						/>
					))}
				</div>
			</div>
			{/* Fixed overlay */}
			<div className='w-screen fixed h-3/5 bottom-0 left-0 bg-black-overlay bg-cover bg-no-repeat pointer-events-none' />
		</>
	);
};
