import React from 'react';
import classNames from 'classnames';
import styles from './DarkField.module.css';

/**
 * Renders a dark field component with the provided children and style classes.
 *
 * @param {Object} props - The properties for the DarkField component.
 * @param {ReactNode} props.children - The child elements to be rendered inside the dark field.
 * @param {string} props.styleClasses - Additional CSS classes to be applied to the dark field.
 * @return {ReactElement} The rendered dark field component.
 */
export const DarkField = ({ children, styleClasses }) => {
	const DarkFieldClasses = classNames(styles.darkFieldContainer, styleClasses);

	return (
		<div className={DarkFieldClasses}>
			{children}
		</div>
	);
};
