// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchUserInput_searchUserInput__NzuYT {

    display: flex;

    width: 100%;

    background-color: transparent;

    padding-left: 1rem;

    padding-right: 0.75rem;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.SearchUserInput_searchUserInput__NzuYT::placeholder {

    --tw-placeholder-opacity: 1;

    color: rgb(62 70 105 / var(--tw-placeholder-opacity))
}

.SearchUserInput_searchUserInput__NzuYT:focus {

    outline: 2px solid transparent;

    outline-offset: 2px
}

.SearchUserInput_searchUserInputContainer__3-pn- {

    width: 100%;

    flex-direction: column;

    align-items: center;

    gap: 1rem
}

.SearchUserInput_searchUserInputContainerError__Y9RiF {

    border-width: 1px;

    border-style: solid;

    --tw-border-opacity: 1;

    border-color: rgb(255 49 49 / var(--tw-border-opacity))
}

.SearchUserInput_searchUserInputMainContent__Qhp\\+B {

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: space-between;

    padding: 0.5rem
}

.SearchUserInput_searchUserInputInviteButton__GhhJg {

    height: 2rem;

    width: 88px
}

.SearchUserInput_searchUserInputCheckCircle__MimGS {

    margin-right: 0.25rem
}

.SearchUserInput_searchUserInputSpinner__11Tad {

    margin-right: 0.25rem
}

@keyframes SearchUserInput_spin__3N5zD {

    to {

        transform: rotate(360deg)
    }
}

.SearchUserInput_searchUserInputSpinner__11Tad {

    animation: SearchUserInput_spin__3N5zD 1s linear infinite
}

.SearchUserInput_searchUserInputInviteButtonText__\\+24eZ {

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.SearchUserInput_searchUserInputInviteText__NM9DL {

    margin-bottom: 1.5rem;

    width: 75%;

    text-align: center;

    font-family: Inter, sans-serif;

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(154 162 197 / var(--tw-text-opacity))
}

@media (min-width: 390px) {

    .SearchUserInput_searchUserInputInviteText__NM9DL {

        width: 66.666667%
    }
}

.SearchUserInput_searchUserInputErrorText__Xp0Ra {

    margin-bottom: 1.5rem;

    text-align: center;

    font-family: Inter, sans-serif;

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 49 49 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/SendNewWhisper/SearchUserInput/SearchUserInput.module.css"],"names":[],"mappings":"AACC;;IAAA,aAA0H;;IAA1H,WAA0H;;IAA1H,6BAA0H;;IAA1H,kBAA0H;;IAA1H,sBAA0H;;IAA1H,8BAA0H;;IAA1H,mBAA0H;;IAA1H,gBAA0H;;IAA1H,oBAA0H;;IAA1H;AAA0H;;AAA1H;;IAAA,2BAA0H;;IAA1H;AAA0H;;AAA1H;;IAAA,8BAA0H;;IAA1H;AAA0H;;AAI1H;;IAAA,WAAyC;;IAAzC,sBAAyC;;IAAzC,mBAAyC;;IAAzC;AAAyC;;AAIzC;;IAAA,iBAA4C;;IAA5C,mBAA4C;;IAA5C,sBAA4C;;IAA5C;AAA4C;;AAI5C;;IAAA,aAAmD;;IAAnD,WAAmD;;IAAnD,mBAAmD;;IAAnD,8BAAmD;;IAAnD;AAAmD;;AAInD;;IAAA,YAAmB;;IAAnB;AAAmB;;AAInB;;IAAA;AAAW;;AAIX;;IAAA;AAAwB;;AAAxB;;IAAA;;QAAA;IAAwB;AAAA;;AAAxB;;IAAA;AAAwB;;AAIxB;;IAAA,8BAAgD;;IAAhD,mBAAgD;;IAAhD,gBAAgD;;IAAhD,oBAAgD;;IAAhD;AAAgD;;AAIhD;;IAAA,qBAAiF;;IAAjF,UAAiF;;IAAjF,kBAAiF;;IAAjF,8BAAiF;;IAAjF,kBAAiF;;IAAjF,iBAAiF;;IAAjF,gBAAiF;;IAAjF,oBAAiF;;IAAjF;AAAiF;;AAAjF;;IAAA;;QAAA;IAAiF;AAAA;;AAIjF;;IAAA,qBAAqE;;IAArE,kBAAqE;;IAArE,8BAAqE;;IAArE,kBAAqE;;IAArE,iBAAqE;;IAArE,gBAAqE;;IAArE,oBAAqE;;IAArE;AAAqE","sourcesContent":[".searchUserInput {\n\t@apply flex pl-4 w-full pr-3 placeholder-grey2 text-white font-sans text-2sm font-normal bg-transparent focus:outline-none;\n}\n\n.searchUserInputContainer {\n\t@apply items-center flex-col gap-4 w-full;\n}\n\n.searchUserInputContainerError {\n\t@apply border-redSpecial border border-solid;\n}\n\n.searchUserInputMainContent {\n\t@apply flex justify-between w-full items-center p-2;\n}\n\n.searchUserInputInviteButton {\n\t@apply h-8 w-[88px];\n}\n\n.searchUserInputCheckCircle {\n\t@apply mr-1;\n}\n\n.searchUserInputSpinner {\n\t@apply mr-1 animate-spin;\n}\n\n.searchUserInputInviteButtonText {\n\t@apply font-sans text-white font-medium text-2sm;\n}\n\n.searchUserInputInviteText {\n\t@apply font-sans text-grey1 font-medium text-xs w-9/12 text-center mb-6 lg:w-8/12;\n}\n\n.searchUserInputErrorText {\n\t@apply font-sans text-redSpecial font-medium text-xs text-center mb-6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchUserInput": `SearchUserInput_searchUserInput__NzuYT`,
	"searchUserInputContainer": `SearchUserInput_searchUserInputContainer__3-pn-`,
	"searchUserInputContainerError": `SearchUserInput_searchUserInputContainerError__Y9RiF`,
	"searchUserInputMainContent": `SearchUserInput_searchUserInputMainContent__Qhp+B`,
	"searchUserInputInviteButton": `SearchUserInput_searchUserInputInviteButton__GhhJg`,
	"searchUserInputCheckCircle": `SearchUserInput_searchUserInputCheckCircle__MimGS`,
	"searchUserInputSpinner": `SearchUserInput_searchUserInputSpinner__11Tad`,
	"spin": `SearchUserInput_spin__3N5zD`,
	"searchUserInputInviteButtonText": `SearchUserInput_searchUserInputInviteButtonText__+24eZ`,
	"searchUserInputInviteText": `SearchUserInput_searchUserInputInviteText__NM9DL`,
	"searchUserInputErrorText": `SearchUserInput_searchUserInputErrorText__Xp0Ra`
};
export default ___CSS_LOADER_EXPORT___;
