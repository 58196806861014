import { CheckCircle } from '../../../assets/svg/CheckCircle';
import NegativeCircle from '../../../assets/svg/NegativeCircle';

export const CHALLENGES_CONFIG = {
	TYPES: [
		{
			id: 'tasks',
			text: 'challenges.tasks.tabTitle'
		},
		{
			id: 'quests',
			text: 'challenges.quests.tabTitle'
		}
	],
	CHALLENGE_DEFAULT_DATA_TYPE: {
		id: null,
		exercise: '',
		appId: '',
		typeId: '',
		rewardsType: '',
		rewardsAmount: null
	},
	BT_CLAIM_LABEL: 'challenges.quests.claimRewardsLabel',
	CARD_IMG_SIZE: 40,
	MODAL_IMG_SIZE: 80
};

export const FEEDBACK_MODAL_CONFIG = {
	default: {
		IconElement: CheckCircle,
		title: '',
		subtitle: ''
	},
	congrats: {
		IconElement: CheckCircle,
		title: 'challenges.feedBackModal.congrats.title',
		subtitle: 'challenges.feedBackModal.unsub.subtitle'
	},
	unsub: {
		IconElement: NegativeCircle,
		title: 'challenges.feedBackModal.unsub.title',
		subtitle: 'challenges.feedBackModal.unsub.title',
		label: 'challenges.task.actionModal.joinChannelLabel'
	},
	server_error: {
		IconElement: NegativeCircle,
		title: 'challenges.feedBackModal.server_error.title',
		subtitle: 'challenges.feedBackModal.unsub.subtitle'
	}
};
