const CaretRightIcon = ({ width = 16, height = 16 }) => (
	<svg
		width={width}
		height={height}
		style={{ minWidth: width, minHeight: height }}
		viewBox='0 0 28 28'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.88128 4.63128C10.223 4.28957 10.777 4.28957 11.1187 4.63128L19.8687 13.3813C20.2104 13.723 20.2104 14.277 19.8687 14.6187L11.1187 23.3687C10.777 23.7104 10.223 23.7104 9.88128 23.3687C9.53957 23.027 9.53957 22.473 9.88128 22.1313L18.0126 14L9.88128 5.86872C9.53957 5.52701 9.53957 4.97299 9.88128 4.63128Z'
			fill='#9AA2C5'
		/>
	</svg>
);

export default CaretRightIcon;
