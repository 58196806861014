// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Friends_friendsContainer__tBAEs {

    display: flex;

    height: 100%;

    flex-direction: column;

    padding-left: 1rem;

    padding-right: 1rem
}

@media (min-width: 320px) {

    .Friends_friendsContainer__tBAEs {

        padding-left: 1rem;

        padding-right: 1rem
    }
}

@media (min-width: 375px) {

    .Friends_friendsContainer__tBAEs {

        padding-left: 1.25rem;

        padding-right: 1.25rem
    }
}

@media (min-width: 390px) {

    .Friends_friendsContainer__tBAEs {

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }
}

@media (min-width: 430px) {

    .Friends_friendsContainer__tBAEs {

        padding-left: 2.25rem;

        padding-right: 2.25rem
    }
}

.Friends_friendsContent__A29-h {

    margin-left: auto;

    margin-right: auto;

    margin-top: 0px;

    margin-bottom: 0px;

    display: flex;

    flex-direction: column;

    gap: 1.5rem
}

.Friends_friendsContentDivider__3u-gv {

    border-top-width: 1px;

    border-style: solid;

    --tw-border-opacity: 1;

    border-color: rgb(255 255 255 / var(--tw-border-opacity));

    opacity: 0.08
}

.Friends_friendsGeneralTitle__G3OkX {

    margin-top: 2rem;

    margin-bottom: 1.5rem
}

.Friends_friendsTitle__ZYcMw {

    display: block;

    font-family: Inter, sans-serif;

    font-size: 1.1875rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.Friends_friendsTitle__ZYcMw span {

    --tw-text-opacity: 1;

    color: rgb(253 217 97 / var(--tw-text-opacity))
}

.Friends_friendsContentEmptyPageText__MsEaI {

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(154 162 197 / var(--tw-text-opacity))
}

.Friends_friendsListContainer__mafL4 {

    margin-top: 0.75rem;

    padding-bottom: 10rem
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Friends/Friends.module.css"],"names":[],"mappings":"AACI;;IAAA,aAAgE;;IAAhE,YAAgE;;IAAhE,sBAAgE;;IAAhE,kBAAgE;;IAAhE;AAAgE;;AAAhE;;IAAA;;QAAA,kBAAgE;;QAAhE;IAAgE;AAAA;;AAAhE;;IAAA;;QAAA,qBAAgE;;QAAhE;IAAgE;AAAA;;AAAhE;;IAAA;;QAAA,oBAAgE;;QAAhE;IAAgE;AAAA;;AAAhE;;IAAA;;QAAA,qBAAgE;;QAAhE;IAAgE;AAAA;;AAIhE;;IAAA,iBAAuC;;IAAvC,kBAAuC;;IAAvC,eAAuC;;IAAvC,kBAAuC;;IAAvC,aAAuC;;IAAvC,sBAAuC;;IAAvC;AAAuC;;AAIvC;;IAAA,qBAAuD;;IAAvD,mBAAuD;;IAAvD,sBAAuD;;IAAvD,yDAAuD;;IAAvD;AAAuD;;AAIvD;;IAAA,gBAAe;;IAAf;AAAe;;AAIf;;IAAA,cAAmD;;IAAnD,8BAAmD;;IAAnD,oBAAmD;;IAAnD,gBAAmD;;IAAnD,oBAAmD;;IAAnD;AAAmD;;AAInD;;IAAA,oBAAwB;;IAAxB;AAAwB;;AAIxB;;IAAA,8BAA+C;;IAA/C,mBAA+C;;IAA/C,gBAA+C;;IAA/C,oBAA+C;;IAA/C;AAA+C;;AAI/C;;IAAA,mBAAiB;;IAAjB;AAAiB","sourcesContent":[".friendsContainer {\n    @apply flex flex-col px-4 sx:px-4 md:px-5 lg:px-6 xl:px-9 h-full;\n}\n\n.friendsContent {\n    @apply flex flex-col gap-6 mx-auto my-0;\n}\n\n.friendsContentDivider {\n    @apply border-t border-solid border-white opacity-[0.08]\n}\n\n.friendsGeneralTitle {\n    @apply mt-8 mb-6\n}\n\n.friendsTitle {\n    @apply text-white font-sans font-bold text-4sm block\n}\n\n.friendsTitle span {\n    @apply text-yellowSpecial\n}\n\n.friendsContentEmptyPageText {\n    @apply text-grey1 font-sans font-normal text-2sm\n}\n\n.friendsListContainer {\n    @apply mt-3 pb-40;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"friendsContainer": `Friends_friendsContainer__tBAEs`,
	"friendsContent": `Friends_friendsContent__A29-h`,
	"friendsContentDivider": `Friends_friendsContentDivider__3u-gv`,
	"friendsGeneralTitle": `Friends_friendsGeneralTitle__G3OkX`,
	"friendsTitle": `Friends_friendsTitle__ZYcMw`,
	"friendsContentEmptyPageText": `Friends_friendsContentEmptyPageText__MsEaI`,
	"friendsListContainer": `Friends_friendsListContainer__mafL4`
};
export default ___CSS_LOADER_EXPORT___;
