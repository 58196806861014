import React from 'react';
import classNames from 'classnames';
import styles from './Card.module.css';

/**
 * Card component representing a card element.
 * @param {string} customClasses - Additional classes to apply to the card.
 * @param {function} cardRef - Reference to the card element.
 * @param {function} onClick - Click event handler.
 * @param {*} children - Child components or text content.
 * @returns JSX element representing a card.
 */
export const Card = ({ customClasses = '', cardRef = null, onClick = () => {}, children = null }) => {
	// Generate the classes for the card element
	const cardClasses = classNames(styles.card, customClasses);

	const handleClick = e => {
		e.stopPropagation();
		onClick();
	};

	return (
		// Card element with specified classes and event handlers
		<div ref={cardRef} className={cardClasses} onClick={handleClick}>
			{children}
		</div>
	);
};
