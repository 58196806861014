import './App.css';
import useTelegram from '../../../hooks/useTelegram/useTelegram';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../ui/Header';
import { Menu } from '../../ui/Menu';
import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { getMobileOperatingSystem } from '../../../utils/defineOperationSystem';
import { CurrentUserContextProvider } from '../../../contexts/CurrentUserContext';

function App() {
	const [userAgent, setUserAgent] = useState('');
	const outletRef = useRef(null);
	const { tg, user } = useTelegram();
	const { pathname } = useLocation();

	const blackColor = getComputedStyle(document.documentElement).getPropertyValue('--color-black').trim();
	const AppStyle = classNames('App', pathname === '/' && 'AppWithEarBackground');

	useEffect(() => {
		if (tg.ready) {
			tg.setHeaderColor(blackColor);
			tg.setBackgroundColor(blackColor);
			setUserAgent(getMobileOperatingSystem());
			tg.expand();
		}
	}, []);

	useEffect(() => {
		outletRef.current.scrollTo(0, 0);
	}, [pathname]);

	return (
		<CurrentUserContextProvider>
			<div className={AppStyle}>
				<Header containerRef={outletRef} />
				<div className='Content' ref={outletRef}>
					<Outlet />
				</div>
				<Menu userAgent={userAgent} />
			</div>
		</CurrentUserContextProvider>
	);
}

export default App;
