import React, { memo } from 'react';
import { CardList } from '../../../ui/CardList';
import { ChallengesCard } from '../ChallengesCard';

/**
 * ChallengesMain component displays the challenges main content.
 * @param {number} tabValue - The value of the active tab.
 * @param {function} onCardClick - The function to handle card click.
 * @returns JSX element
 */
const ChallengesMain = memo(({ tabValue, onCardClick }) => {
	const TEST_DATA = [
		{
			id: 'active',
			title: 'challenges.types.active',
			lists: {
				tasks: [
					{
						id: 0,
						exercise: 'challenges.tasks.exercises.join.tg',
						appId: 'tg',
						typeId: 'join',
						rewardsType: 'whisper-coin',
						rewardsAmount: 12,
						options: { isComplete: false }
					},
					{
						id: 1,
						exercise: 'challenges.tasks.exercises.join.inst',
						appId: 'inst',
						typeId: 'join',
						rewardsType: 'energy',
						rewardsAmount: 200,
						options: { isComplete: false }
					},
					{
						id: 2,
						exercise: 'challenges.tasks.exercises.join.x',
						appId: 'x',
						typeId: 'join',
						rewardsType: 'energy',
						rewardsAmount: 200,
						options: { isComplete: false }
					}
				],
				quests: [
					{
						id: 0,
						exercise: 'challenges.tasks.exercises.join.tg',
						appId: 'tg',
						typeId: 'join',
						rewardsType: 'whisper-coin',
						rewardsAmount: 12,
						options: { isComplete: false }
					},
					{
						id: 1,
						exercise: 'challenges.tasks.exercises.join.inst',
						appId: 'inst',
						typeId: 'join',
						rewardsType: 'energy',
						rewardsAmount: 200,
						options: { isComplete: false }
					},
					{
						id: 2,
						exercise: 'challenges.tasks.exercises.join.x',
						appId: 'x',
						typeId: 'join',
						rewardsType: 'energy',
						rewardsAmount: 200,
						options: { isComplete: false }
					}
				]
			}
		},
		{
			id: 'finished',
			title: 'challenges.types.finished',
			lists: {
				tasks: [
					{
						id: 0,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 1,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 2,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 3,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 4,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					}
				],
				quests: [
					{
						id: 0,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 1,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 2,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 3,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					},
					{
						id: 4,
						appId: 'tg',
						typeId: 'join',
						exercise: 'Join Telegram channel',
						rewardsType: 'whisper-coin',
						rewardsAmount: 2,
						options: { isComplete: true }
					}
				]
			}
		}
	];

	// Render the ChallengesMain component
	return (
		<>
			{TEST_DATA.map(({ id, title, lists }) => (
				<CardList
					key={id}
					title={title}
					list={lists[tabValue]}
					CardElement={ChallengesCard}
					dataType={tabValue}
					onCardClick={onCardClick}
				/>
			))}
		</>
	);
});

export default ChallengesMain;
