import React from 'react';
import { CheckCircle } from '../../../../../assets/svg/CheckCircle';
import CaretRightIcon from '../../../../../assets/svg/CaretRightIcon';
import { CHALLENGES_CONFIG } from '../../ChallengesConfig';
import { Spinner } from '../../../../../assets/svg/Spinner';
import { useTranslation } from 'react-i18next';

/**
 * Component for rendering a button in Challenges Card
 * @param {Object} challengesCardStatus - Status of the challenges card
 * @param {string} dataType - Type of data
 * @param {Function} onClick - Click event handler
 * @param setChallengesCardStatus
 */
export const ChallengesCardButton = ({ challengesCardStatus, dataType, onClick, setChallengesCardStatus }) => {
	// Destructuring CHALLENGES_CONFIG object
	const { TYPES, BT_CLAIM_LABEL } = CHALLENGES_CONFIG;
	const { t } = useTranslation();
	// Checking if it's a quest or a task
	const isQuest = dataType === TYPES[1].id;
	const isTask = dataType === TYPES[0].id;

	// Handling completion status
	if (challengesCardStatus.isComplete) {
		return <CheckCircle />;
	}

	// Click event handler for the button
	const handleButtonClick = e => {
		e.stopPropagation();

		setChallengesCardStatus(challengesCardStatus => ({ ...challengesCardStatus, isLoaded: false }));
		onClick();
	};

	// Status element based on loaded status
	const StatusElement = () => {
		if (!challengesCardStatus.isLoaded) {
			return <Spinner className='spinner-loader' />;
		}

		return (
			<>
				{isTask && <CaretRightIcon width={28} height={28} />}
				{isQuest && t(BT_CLAIM_LABEL)}
			</>
		);
	};

	// Rendering the button
	return (
		<button
			onClick={handleButtonClick}
			className={
				isQuest ? 'blue-button min-w-22 h-8 text-white text-medium text-2sm flex justify-center items-center' : ''
			}
			disabled={!challengesCardStatus.isLoaded || challengesCardStatus.isComplete}>
			{StatusElement()}
		</button>
	);
};
