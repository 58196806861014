import React from 'react';
import { Card } from '../../../ui/Card';
import styles from './FriendCard.module.css';
import { EnergyIcon } from '../../../../assets/svg/EnergyIcon';
import { WhisperCoin } from '../../../../assets/svg/WhisperCoin';

/**
 * Renders a card component displaying information about a friend.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.data - The data object containing information about the friend.
 * @param {string} props.data.userAvatar - The URL of the friend's avatar image.
 * @param {string} props.data.username - The username of the friend.
 * @param {string} props.data.rewardCoins - The reward amount in coins for the friend.
 * @param {string} props.data.rewardType - The type of reward ('coin' or 'energy').
 * @return {JSX.Element} The rendered card component.
 */

export const FriendCard = ({ data }) => {
	return (
		<Card customClasses={styles.friendCardContainer}>
			<div className={styles.friendCardUserInfo}>
				<img src={data.userAvatar} alt='avatar' className={styles.friendCardUserImage} />
				<span className={styles.friendCardUsername}>{data.username}</span>
			</div>
			<div className={styles.friendCardReward}>
				<span>+{data.rewardCoins}</span>
				<div>{data.rewardType === 'coin' ? <WhisperCoin width={16} height={16} /> : <EnergyIcon />}</div>
			</div>
		</Card>
	);
};
