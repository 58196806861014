// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewWhisperField_newWhisperFieldContainer__5UxeV {
    display: flex;
    max-height: 20rem;
    min-height: 9.75rem;
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.5rem;
}

.NewWhisperField_newWhisperFieldTextarea__qMuRe {
    resize: none;
    background-color: transparent;
    font-family: Inter, sans-serif;
    font-size: 0.938rem;
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.NewWhisperField_newWhisperFieldTextarea__qMuRe::placeholder {
    font-family: Inter, sans-serif;
    --tw-text-opacity: 1;
    color: rgb(62 70 105 / var(--tw-text-opacity));
}

.NewWhisperField_newWhisperFieldTextarea__qMuRe:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.NewWhisperField_newWhisperFieldToggle__UVcgq {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.NewWhisperField_newWhisperFieldCharacters__zN4Ee {
    font-family: Inter, sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(62 70 105 / var(--tw-text-opacity));
}

.NewWhisperField_textarea__vJ0nD {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 40px;
    line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/SendNewWhisper/NewWhisperField/NewWhisperField.module.css"],"names":[],"mappings":"AACI;IAAA,aAAsF;IAAtF,iBAAsF;IAAtF,mBAAsF;IAAtF,WAAsF;IAAtF,sBAAsF;IAAtF,oBAAsF;IAAtF,8BAAsF;IAAtF,WAAsF;IAAtF;AAAsF;;AAItF;IAAA,YAA4I;IAA5I,6BAA4I;IAA5I,8BAA4I;IAA5I,mBAA4I;IAA5I,gBAA4I;IAA5I,oBAA4I;IAA5I;AAA4I;;AAA5I;IAAA,8BAA4I;IAA5I,oBAA4I;IAA5I;AAA4I;;AAA5I;IAAA,8BAA4I;IAA5I;AAA4I;;AAI5I;IAAA,aAAwC;IAAxC,mBAAwC;IAAxC;AAAwC;;AAIxC;IAAA,8BAA+C;IAA/C,kBAA+C;IAA/C,iBAA+C;IAA/C,gBAA+C;IAA/C,oBAA+C;IAA/C;AAA+C;;AAGnD;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".newWhisperFieldContainer {\n    @apply flex flex-col justify-between p-6 min-h-156 items-stretch gap-6 w-full max-h-80;\n}\n\n.newWhisperFieldTextarea {\n    @apply bg-transparent  placeholder:text-grey2 text-white placeholder:font-sans font-sans text-2sm font-normal focus:outline-none resize-none;\n}\n\n.newWhisperFieldToggle {\n    @apply flex justify-between items-center;\n}\n\n.newWhisperFieldCharacters {\n    @apply text-grey2 text-xs font-sans font-medium;\n}\n\n.textarea {\n    display: block;\n    width: 100%;\n    overflow: hidden;\n    resize: none;\n    min-height: 40px;\n    line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newWhisperFieldContainer": `NewWhisperField_newWhisperFieldContainer__5UxeV`,
	"newWhisperFieldTextarea": `NewWhisperField_newWhisperFieldTextarea__qMuRe`,
	"newWhisperFieldToggle": `NewWhisperField_newWhisperFieldToggle__UVcgq`,
	"newWhisperFieldCharacters": `NewWhisperField_newWhisperFieldCharacters__zN4Ee`,
	"textarea": `NewWhisperField_textarea__vJ0nD`
};
export default ___CSS_LOADER_EXPORT___;
