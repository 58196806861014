import React, { useState, Fragment, useMemo, useRef } from 'react';
import { CHALLENGES_CONFIG, FEEDBACK_MODAL_CONFIG } from './ChallengesConfig';
import styles from './Challenges.module.css';
import { ChallengesTaskModal } from './ChallengesTaskModal';
import ChallengesMain from './ChallengesMain/ChallengesMain';
import { Modal } from '../../ui/Modal';
import { Button } from '../../ui/Button';
import { ChallengesRewards } from './ChellengesRewards';
import { SegmentsController } from '../../ui/SegmentsController';
import { useTranslation } from 'react-i18next';

/**
 * Component for Challenges section.
 *
 * @param {} props - Component props
 * @returns Challenges component
 */
export const Challenges = ({}) => {
	// Destructuring CHALLENGES_CONFIG for default data type and modal image size
	const { CHALLENGE_DEFAULT_DATA_TYPE, MODAL_IMG_SIZE } = CHALLENGES_CONFIG;

	// State variables for tab value, task modal, feedback modal, and current task
	const [tabValue, setTabValue] = useState(CHALLENGES_CONFIG.TYPES[0].id);
	const [taskModalIsOpen, setTaskModalIsOpen] = useState(false);
	const [feedbackModalStates, setFeedbackModalStates] = useState({ isOpen: false, feedback: 'default' });
	const [currentTask, setCurrentTask] = useState(CHALLENGE_DEFAULT_DATA_TYPE);

	const { t } = useTranslation();

	// ref for challenges section
	const challengesRef = useRef(null);

	// Function to open task modal
	const openTaskModal = () => setTaskModalIsOpen(true);

	// Function to open claim modal
	const openClaimModal = feedback => setFeedbackModalStates(prevStatuses => ({ isOpen: true, feedback: 'congrats' }));

	// Function to close task modal
	const closeTaskModal = () => {
		setTaskModalIsOpen(false);
		setCurrentTask(CHALLENGE_DEFAULT_DATA_TYPE);
	};

	// Function to close claim modal
	const closeClaimModal = () => setFeedbackModalStates(prevStatuses => ({ isOpen: false, feedback: 'default' }));

	// Function to handle task button click
	const taskButtonClickHandler = data => {
		setCurrentTask(data);
		openTaskModal();
	};

	// Memoized function to handle button click based on tab value
	const onButtonClick = useMemo(() => {
		switch (tabValue) {
			case CHALLENGES_CONFIG.TYPES[0].id:
				return data => taskButtonClickHandler(data);
			case CHALLENGES_CONFIG.TYPES[1].id:
				return feedback => openClaimModal(feedback);
		}
	}, [tabValue, CHALLENGES_CONFIG]);

	// Function to handle tab click
	const handleTabClick = tabId => {
		tabValue !== tabId && setTabValue(tabId);
	};

	// Function to render feedback modal content
	const feedbackModalContent = ({
		IconElement = FEEDBACK_MODAL_CONFIG.congrats.IconElement,
		title = '',
		subtitle = ''
	}) => {
		return (
			<div className='flex flex-col items-center gap-5'>
				<IconElement width={MODAL_IMG_SIZE} height={MODAL_IMG_SIZE} />
				<div className='flex flex-col items-center text-center gap-2'>
					<h3 className='text-xl leading-6 font-bold text-white'>{t(title)}</h3>
					<p className='text-2sm leading-5 font-normal text-grey1'>{t(subtitle)}</p>
				</div>
				{feedbackModalStates.feedback === 'unsub' && (
					<Button styleClasses='px-6 py-1.5'>{t(FEEDBACK_MODAL_CONFIG.unsub.label)}</Button>
				)}
				{feedbackModalStates.feedback === 'congrats' && (
					<ChallengesRewards
						amountStyleClasses='text-white font-medium text-3sm gap-1 flex items-center justify-center leading-5-5'
						iconSize={24}
					/>
				)}
			</div>
		);
	};

	// Return the Challenges component
	return (
		<>
			<div className={styles.challenges} ref={challengesRef}>
				<SegmentsController
					config={CHALLENGES_CONFIG.TYPES}
					setActiveTab={handleTabClick}
					containerRef={challengesRef}
				/>
				<ChallengesMain tabValue={tabValue} onCardClick={onButtonClick} />
			</div>
			<ChallengesTaskModal modalIsOpen={taskModalIsOpen} currentTask={currentTask} closeModal={closeTaskModal} />
			<Modal
				customStyles={'w-[294px] h-[248px] py-6 px-10'}
				isOpen={feedbackModalStates.isOpen}
				onClose={closeClaimModal}>
				{feedbackModalContent(FEEDBACK_MODAL_CONFIG[feedbackModalStates.feedback])}
			</Modal>
		</>
	);
};
