// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DarkField_darkFieldContainer__MjwWa {
    position: relative;
    display: flex;
    border-radius: 1.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(21 26 43 / var(--tw-bg-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/ui/DarkField/DarkField.module.css"],"names":[],"mappings":"AACI;IAAA,kBAA4C;IAA5C,aAA4C;IAA5C,qBAA4C;IAA5C,kBAA4C;IAA5C;AAA4C","sourcesContent":[".darkFieldContainer {\n    @apply flex bg-darkBlue rounded-3xl relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"darkFieldContainer": `DarkField_darkFieldContainer__MjwWa`
};
export default ___CSS_LOADER_EXPORT___;
