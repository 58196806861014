// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__fzgN8 {
	position: relative;
	align-items: center;
	overflow: hidden;
	border-radius: 1.5rem;
	border-width: 1px;
	border-color: transparent
}

.Card_card__fzgN8::before {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
	border-radius: 1.5rem;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	--tw-content: "";
	content: var(--tw-content);
	mask: var(--gradient-component-mask);
	mask-composite: exclude;
	background: var(--gradient-component-background);
	z-index: -5
}

.Card_card__fzgN8::after {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	z-index: -10;
	height: 100%;
	width: 100%;
	border-radius: 1.5rem;
	background-image: linear-gradient(0deg, rgba(16, 8, 29, 0.64), rgba(16, 8, 29, 0.64)),linear-gradient(104.04deg, rgba(61, 103, 241, 0.32) -8.88%, rgba(61, 103, 241, 0) 57%);
	--tw-content: "";
	content: var(--tw-content)
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Card/Card.module.css"],"names":[],"mappings":"AACC;CAAA,kBAAkF;CAAlF,mBAAkF;CAAlF,gBAAkF;CAAlF,qBAAkF;CAAlF,iBAAkF;CAAlF;AAAkF;;AAIlF;CAAA,kBAAiH;CAAjH,SAAiH;CAAjH,UAAiH;CAAjH,QAAiH;CAAjH,WAAiH;CAAjH,YAAiH;CAAjH,WAAiH;CAAjH,qBAAiH;CAAjH,iBAAiH;CAAjH,mBAAiH;CAAjH,yBAAiH;CAAjH,gBAAiH;CAAjH,0BAAiH;CACjH,oCAAoC;CAEpC,uBAAuB;CACvB,gDAAgD;CAChD;AALiH;;AASjH;CAAA,kBAA2G;CAA3G,SAA2G;CAA3G,UAA2G;CAA3G,QAA2G;CAA3G,WAA2G;CAA3G,YAA2G;CAA3G,YAA2G;CAA3G,WAA2G;CAA3G,qBAA2G;CAA3G,4KAA2G;CAA3G,gBAA2G;CAA3G;AAA2G","sourcesContent":[".card {\n\t@apply items-center overflow-hidden relative rounded-3xl border border-transparent;\n}\n\n.card::before {\n\t@apply w-full h-full border border-solid border-transparent rounded-3xl absolute content-[\"\"] inset-x-0 inset-y-0;\n\tmask: var(--gradient-component-mask);\n\t-webkit-mask-composite: destination-out;\n\tmask-composite: exclude;\n\tbackground: var(--gradient-component-background);\n\tz-index: -5;\n}\n\n.card::after {\n\t@apply absolute w-full h-full rounded-3xl content-[\"\"] -z-10 bg-whispercard-bg-gradient inset-x-0 inset-y-0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__fzgN8`
};
export default ___CSS_LOADER_EXPORT___;
