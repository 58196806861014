// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardList_cardListBox__kTf7e {
}

.CardList_cardListHeader__yFAeS {
	width: 100%;
	padding-left: 4rem;
	padding-right: 4rem;
	padding-top: 0.5rem;
	padding-bottom: 0.75rem
}

@media (max-width: 320px) {

	.CardList_cardListHeader__yFAeS {
		padding-left: 1rem;
		padding-right: 1rem
	}
}

.CardList_cardListTitle__PsEu2 {
	font-size: 0.75rem;
	font-weight: 500;
	line-height: 0.875rem;
	--tw-text-opacity: 1;
	color: rgb(154 162 197 / var(--tw-text-opacity))
}

.CardList_cardList__tADUm {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.75rem;
	-webkit-overflow-scrolling: touch;
	overflow-y: auto
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/CardList/CardList.module.css"],"names":[],"mappings":"AAAA;AACA;;AAGC;CAAA,WAAuC;CAAvC,kBAAuC;CAAvC,mBAAuC;CAAvC,mBAAuC;CAAvC;AAAuC;;AAAvC;;CAAA;EAAA,kBAAuC;EAAvC;CAAuC;AAAA;;AAIvC;CAAA,kBAAiD;CAAjD,gBAAiD;CAAjD,qBAAiD;CAAjD,oBAAiD;CAAjD;AAAiD;;AAIjD;CAAA,aAAuC;CAAvC,sBAAuC;CAAvC,mBAAuC;CAAvC,YAAuC;CACvC,iCAAiC;CACjC;AAFuC","sourcesContent":[".cardListBox {\n}\n\n.cardListHeader {\n\t@apply w-full pt-2 pb-3 px-16 m-sx:px-4;\n}\n\n.cardListTitle {\n\t@apply text-xs leading-3-5 text-grey1 font-medium;\n}\n\n.cardList {\n\t@apply flex flex-col gap-3 items-center;\n\t-webkit-overflow-scrolling: touch;\n\toverflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardListBox": `CardList_cardListBox__kTf7e`,
	"cardListHeader": `CardList_cardListHeader__yFAeS`,
	"cardListTitle": `CardList_cardListTitle__PsEu2`,
	"cardList": `CardList_cardList__tADUm`
};
export default ___CSS_LOADER_EXPORT___;
