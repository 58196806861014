import React, { useMemo } from 'react';
import styles from '../WhisperCard.module.css';
import classNames from 'classnames';
import { WHISPER_CARD_CONFIG } from '../WhisperCardConfig';
import useResponsiveText from '../../../../../hooks/useResponsiveText/useResponsiveText';
import { useTranslation } from 'react-i18next';

/**
 * Component representing the content of a WhisperCard.
 * @param {Object} props - The props object
 * @param {Object} props.data - The data for the WhisperCard
 * @param {Object} props.whisperCardStatus - The status of the WhisperCard
 */
export const WhisperCardContent = ({ data, whisperCardStatus }) => {
	const {
		CONSTANTS: { SHORT_MESSAGE_SYMBOL_AMOUNT }
	} = WHISPER_CARD_CONFIG;

	const { visibleString, containerRef } = useResponsiveText({
		string: data.message,
		shouldUseResponsiveText: whisperCardStatus.isCollapsed
	});

	const { t } = useTranslation();

	const messageIsShort = data.message?.length > SHORT_MESSAGE_SYMBOL_AMOUNT && whisperCardStatus.isCollapsed;

	const whispercardMessage = classNames(styles.whispercardMessage, messageIsShort && 'clipped-text');

	const whispercardContent = classNames(styles.whispercardContent, !whisperCardStatus.isOpened && 'blur-sm');

	return (
		<div className={whispercardContent} ref={containerRef}>
			<div className={styles.whispercardAvatar} />
			<div className={styles.whispercardData}>
				<header className={styles.whispercardUserInfo}>
					<p className={styles.whispercardUsername}>{data.username || t('main.card.anonUsername')}</p>
					<time className={styles.whispercardReceiptDate} dateTime={new Date().toDateString()}>
						{data.sendDate}
					</time>
				</header>
				<p className={whispercardMessage}>{visibleString}</p>
			</div>
		</div>
	);
};
