import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import en from './i18n/en/translation.json';
import ru from './i18n/ru/translation.json';

i18n.use(initReactI18next).init({
	debug: false,
	fallbackLng: 'en',
	resources: {
		en: {
			translation: en
		},
		ru: {
			translation: ru
		}
	}
});

export default i18n;
