import React from 'react';
import { DarkField } from '../../../ui/DarkField';
import { Button } from '../../../ui/Button';
import styles from './NewWhisperEnergyBar.module.css';
import { EnergyIcon } from '../../../../assets/svg/EnergyIcon';
import { useTranslation } from 'react-i18next';

/**
 * Renders a new whisper energy bar component.
 *
 * @param {Object} props - The properties for the component.
 * @param {boolean} props.disablesState - Whether the button is disabled.
 * @param {string} props.availableEnergy - The available energy.
 * @param {string} props.allEnergy - The total energy.
 * @param {string} props.requiredEnergy - The required energy for sending a whisper.
 * @return {JSX.Element} The rendered new whisper energy bar component.
 */
export const NewWhisperEnergyBar = ({ disablesState, availableEnergy, allEnergy, requiredEnergy, onButtonCLick }) => {
	const { t } = useTranslation();

	return (
		<DarkField styleClasses={styles.newWhisperEnergyContainer}>
			<div className={styles.newWhisperEnergyInformation}>
				<EnergyIcon />
				{availableEnergy}
				<span>/{allEnergy}</span>
			</div>
			<Button disabled={disablesState} styleClasses={styles.newWhisperEnergyButton} onClick={onButtonCLick}>
				<span>{t('newWhispers-page.buttons.sendButton')}</span>
				<div className={styles.newWhisperEnergyButtonPrice}>
					{requiredEnergy}
					<EnergyIcon fill={disablesState ? 'var(--color-grey1)' : undefined} />
				</div>
			</Button>
		</DarkField>
	);
};
