// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	12.5% {
		transform: rotate(45deg);
	}
	25% {
		transform: rotate(90deg);
	}
	37.5% {
		transform: rotate(135deg);
	}
	50% {
		transform: rotate(180deg);
	}
	62.5% {
		transform: rotate(225deg);
	}
	75% {
		transform: rotate(270deg);
	}
	87.5% {
		transform: rotate(315deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
`, "",{"version":3,"sources":["webpack://./src/styles/animations.css"],"names":[],"mappings":"AAAA;CACC;EACC,uBAAuB;CACxB;CACA;EACC,wBAAwB;CACzB;CACA;EACC,wBAAwB;CACzB;CACA;EACC,yBAAyB;CAC1B;CACA;EACC,yBAAyB;CAC1B;CACA;EACC,yBAAyB;CAC1B;CACA;EACC,yBAAyB;CAC1B;CACA;EACC,yBAAyB;CAC1B;CACA;EACC,yBAAyB;CAC1B;AACD","sourcesContent":["@keyframes rotation {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\t12.5% {\n\t\ttransform: rotate(45deg);\n\t}\n\t25% {\n\t\ttransform: rotate(90deg);\n\t}\n\t37.5% {\n\t\ttransform: rotate(135deg);\n\t}\n\t50% {\n\t\ttransform: rotate(180deg);\n\t}\n\t62.5% {\n\t\ttransform: rotate(225deg);\n\t}\n\t75% {\n\t\ttransform: rotate(270deg);\n\t}\n\t87.5% {\n\t\ttransform: rotate(315deg);\n\t}\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
