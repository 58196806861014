import React from 'react';
import { GradientComponent } from '../GradientComponent';
import styles from './WhisperAmount.module.css';
/**
 * Renders a component displaying the amount of whispers.
 *
 * @param {Object} props - The properties object.
 * @param {number} props.amount - The amount of whispers to display.
 * @return {JSX.Element} The rendered component.
 */
export const WhisperAmount = ({ amount }) => {
	return (
		<div className={styles.whisperAmount}>
			<div className={styles.whisperAmountText}>{amount}</div>
			<div className='bg-cover h-6 w-6 ml-2 bg-[url("../assets/svg/whisper-coin.svg")]' />
			<GradientComponent />
		</div>
	);
};
