/**
 * Renders an SVG icon of NewWhisperIcon.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.fill - The color to fill the icon with.
 * @return {JSX.Element} The rendered SVG icon.
 */
export const NewWhisperIcon = ({ fill, width = 40, height = 40 }) => {
	return (
		<svg
			width={width}
			height={height}
			style={{ minWidth: width, minHeight: height }}
			fill='none'
			viewBox='0 0 40 40'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M15.96 20c1.058 0 1.916-.84 1.916-1.875 0-1.035-.858-1.875-1.915-1.875-1.058 0-1.916.84-1.916 1.875 0 1.035.858 1.875 1.916 1.875ZM24.899 20c1.057 0 1.915-.84 1.915-1.875 0-1.035-.858-1.875-1.915-1.875-1.058 0-1.916.84-1.916 1.875 0 1.035.858 1.875 1.916 1.875Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20.43 6.25c-3.402 0-6.66 1.323-9.057 3.67-2.397 2.347-3.739 5.524-3.739 8.83v10h22.16c.283 0 .559.097.78.274l2.65 2.123V18.75c0-3.306-1.34-6.483-3.737-8.83-2.398-2.347-5.655-3.67-9.057-3.67ZM9.624 8.134c2.87-2.81 6.757-4.384 10.806-4.384 4.048 0 7.935 1.574 10.806 4.384 2.87 2.811 4.489 6.63 4.489 10.616v15a1.25 1.25 0 0 1-2.032.976l-4.339-3.476H6.384c-.69 0-1.25-.56-1.25-1.25V18.75c0-3.987 1.618-7.805 4.49-10.616Z'
				fill={fill}
			/>
		</svg>
	);
};
