import { FriendCard } from './FriendCard';

export const FRIENDS_DESCRIPTION_CONFIG = [
	{
		id: 1,
		title: 'friends-page.tabs.referrals.description.instruction.firstStep'
	},
	{
		id: 2,
		title: 'friends-page.tabs.referrals.description.instruction.secondStep'
	},
	{
		id: 3,
		title: 'friends-page.tabs.referrals.description.instruction.thirdStep'
	}
];

export const SEGMENTS_CONTROLLER_CONFIG = [
	{
		id: 'referrals',
		text: "friends-page.tabs.referrals.name"
	},
	{
		id: 'friendList',
		text: "friends-page.tabs.friends.name"
	}
];

export const INVITED_FRIENDS_DATA = {
	lists: [
		{
			title: "friends-page.tabs.friends.listTitle",
			items: []
		}
	],
	CardElement: FriendCard
};

export const FRIENDS_COPY_BUTTONS_CONFIG = {
	mainButton: {
		title: "friends-page.tabs.referrals.buttons.copyButtonText",
		linkText: "friends-page.tabs.referrals.buttons.copyButtonLinkText"
	}
}