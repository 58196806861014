import React from 'react';
import styles from './EmptyPage.module.css';

/**
 * Renders an empty page component with the provided children.
 *
 * @param {ReactNode} children - The content to be rendered inside the empty page.
 * @return {JSX.Element} The empty page component.
 */

export const EmptyPage = ({ children }) => {
	return (
		<div className={styles.emptyPageContainer}>
			{children}
		</div>
	);
};