import React, { useContext, useEffect, useState } from 'react';
import { UserInformation } from '../UserData/UserInformation';
import { WhisperAmount } from '../WhisperAmount';
import styles from './Header.module.css';
import classNames from 'classnames';
import { USERS_DATA } from './config';
import { useScrollTrigger } from '../../../hooks/useScrollTrigger/useScrollTrigger';
import { useLocation } from 'react-router-dom';
import { CurrentUserContext } from '../../../contexts/CurrentUserContext';

/**
 * Renders the Header component with user information and whisper amount.
 *
 * @param {Object} props - The properties object.
 * @param {React.RefObject} props.containerRef - The reference to the container element.
 * @return {JSX.Element} The rendered Header component.
 */
export const Header = ({ containerRef }) => {
	const [showUserInformation, setShowUserInformation] = useState(false);
	const showUserInformationScroll = useScrollTrigger(2, containerRef); // Trigger when scrolled more than 2px
	const headerBlur = classNames(styles.header, showUserInformationScroll && styles.headerBlur);
	const location = useLocation();
	const { currentUser } = useContext(CurrentUserContext);

	useEffect(() => {
		location.pathname !== '/' || showUserInformationScroll
			? setShowUserInformation(true)
			: setShowUserInformation(false);
	}, [location, showUserInformationScroll]);

	return (
		<div className={headerBlur}>
			<div className={styles.headerContainer}>
				<div className={styles.headerUserInformation} />
				{showUserInformation && (
					<UserInformation userName={currentUser.username} notifications={USERS_DATA.notifications} />
				)}
			</div>
			<WhisperAmount amount={currentUser.balance} />
		</div>
	);
};
