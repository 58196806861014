import React from 'react';
import styles from './GradientComponent.module.css';

/**
 * Renders a gradient component with a specified background color.
 *
 * @return {JSX.Element} A div element with a gradient background.
 */
export const GradientComponent = () => {
	return <div className={styles.gradient} />;
};
