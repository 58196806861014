export const WHISPER_CARD_CONFIG = {
	CONSTANTS: {
		SHORT_MESSAGE_SYMBOL_AMOUNT: 28
	},
	STRINGS: {
		REACTIONS_TITLE: {
			REACT: 'main.card.reactionTitles.react',
			UNREACT: 'main.card.reactionTitles.unreact'
		}
	},
	ASSETS: {
		SMILES: [{ id: 'hearts-smile' }, { id: 'grinning-smile' }, { id: 'neutral-smile' }, { id: 'vomit-smile' }]
	}
};
