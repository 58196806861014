// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyButtonsBlock_buttonsContainer__wNTa7 {

    display: flex;

    align-items: center;

    justify-content: space-between
}

@media (min-width: 320px) {

    .CopyButtonsBlock_buttonsContainer__wNTa7 {

        gap: 0.25rem
    }
}

.CopyButtonsBlock_buttonText__OLhIf {

    display: flex;

    align-items: center;

    justify-content: center;

    white-space: nowrap;

    padding-left: 3.5rem;

    padding-right: 3.5rem;

    padding-top: 0.875rem;

    padding-bottom: 0.875rem;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.CopyButtonsBlock_buttonText__OLhIf:hover {

    opacity: 0.9
}

.CopyButtonsBlock_buttonText__OLhIf:active {

    opacity: 0.9
}

@media (min-width: 320px) {

    .CopyButtonsBlock_buttonText__OLhIf {

        padding-left: 4.375rem;

        padding-right: 4.375rem
    }
}

@media (min-width: 375px) {

    .CopyButtonsBlock_buttonText__OLhIf {

        padding-left: 5rem;

        padding-right: 5rem
    }
}

.CopyButtonsBlock_copyButton__1DFcJ {

    display: flex;

    width: 3rem;

    align-items: center;

    justify-content: center;

    padding: 0.75rem
}

.CopyButtonsBlock_buttonsDivider__D6YFM {

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(62 70 105 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/CopyButtonsBlock/CopyButtonsBlock.module.css"],"names":[],"mappings":"AACC;;IAAA,aAAiD;;IAAjD,mBAAiD;;IAAjD;AAAiD;;AAAjD;;IAAA;;QAAA;IAAiD;AAAA;;AAIjD;;IAAA,aAAqK;;IAArK,mBAAqK;;IAArK,uBAAqK;;IAArK,mBAAqK;;IAArK,oBAAqK;;IAArK,qBAAqK;;IAArK,qBAAqK;;IAArK,wBAAqK;;IAArK,8BAAqK;;IAArK,mBAAqK;;IAArK,gBAAqK;;IAArK,oBAAqK;;IAArK;AAAqK;;AAArK;;IAAA;AAAqK;;AAArK;;IAAA;AAAqK;;AAArK;;IAAA;;QAAA,sBAAqK;;QAArK;IAAqK;AAAA;;AAArK;;IAAA;;QAAA,kBAAqK;;QAArK;IAAqK;AAAA;;AAIrK;;IAAA,aAAgD;;IAAhD,WAAgD;;IAAhD,mBAAgD;;IAAhD,uBAAgD;;IAAhD;AAAgD;;AAIhD;;IAAA,8BAAgD;;IAAhD,mBAAgD;;IAAhD,gBAAgD;;IAAhD,oBAAgD;;IAAhD;AAAgD","sourcesContent":[".buttonsContainer {\n\t@apply flex sx:gap-1 justify-between items-center;\n}\n\n.buttonText {\n\t@apply sx:px-18 px-14 text-white text-2sm font-sans font-medium flex items-center justify-center whitespace-nowrap py-3.5 md:px-20 hover:opacity-90 active:opacity-90;\n}\n\n.copyButton {\n\t@apply w-12 p-3 flex justify-center items-center;\n}\n\n.buttonsDivider {\n\t@apply text-grey2 text-2sm font-sans font-medium;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonsContainer": `CopyButtonsBlock_buttonsContainer__wNTa7`,
	"buttonText": `CopyButtonsBlock_buttonText__OLhIf`,
	"copyButton": `CopyButtonsBlock_copyButton__1DFcJ`,
	"buttonsDivider": `CopyButtonsBlock_buttonsDivider__D6YFM`
};
export default ___CSS_LOADER_EXPORT___;
