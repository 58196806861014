import axios from 'axios';
import { AUTH_TOKEN } from './constants';
import { postAccessToken } from '../utils/api';

export const ApiClient = axios.create({
	baseURL: process.env.REACT_APP_API_HOST,
	headers: { Authorization: AUTH_TOKEN }
});

ApiClient.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		let accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			config.headers.Authorization = accessToken;
		}

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

ApiClient.interceptors.response.use(
	response => response,
	async error => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			try {
				const response = await postAccessToken(window.Telegram.WebApp.initDataUnsafe.user.id);
				const { token } = response.data;

				localStorage.setItem('accessToken', 'Bearer ${token}');

				originalRequest.headers.Authorization = `Bearer ${token}`;

				return axios(originalRequest);
			} catch {}
		}

		return Promise.reject(error);
	}
);
