import { useEffect, useState } from 'react';

/**
 * Custom hook to detect if the scroll position has passed a given threshold for a specific container.
 * @param {number} threshold - The scroll Y position at which the trigger occurs.
 * @param {Object} scrollContainerRef - A reference to the scrollable container.
 * @returns {boolean} - Returns true if the scroll Y position is greater than the threshold.
 */
export const useScrollTrigger = (threshold = 10, scrollContainerRef) => {
	const [trigger, setTrigger] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (scrollContainerRef.current) {
				const scrollTop = scrollContainerRef.current.scrollTop;
				scrollTop > threshold ? setTrigger(true) : setTrigger(false);
			}
		};

		const scrollContainer = scrollContainerRef.current;

		if (scrollContainer) {
			scrollContainer.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (scrollContainer) {
				scrollContainer.removeEventListener('scroll', handleScroll);
			}
		};
	}, [threshold, scrollContainerRef]);

	return trigger;
};

