import React from 'react';
import styles from './CardList.module.css';
import { useTranslation } from 'react-i18next';

/**
 * CardList component renders a list of cards with a specified title and CardElement.
 * @param {Array} list - The list of data to be displayed.
 * @param {string} title - The title of the card list.
 * @param {React.Component} CardElement - The component used to render each card.
 * @param props
 * @returns JSX element
 */
export const CardList = ({ list = [], title = '', CardElement = () => {}, ...specialProps }) => {
	const { t } = useTranslation();

	// Render the CardList component
	return (
		<div className={styles.cardListBox}>
			<div className={styles.cardListHeader}>
				<h2 className={styles.cardListTitle}>{t(title, { count: list.length })}</h2>
			</div>
			<div className={styles.cardList}>
				{list.map((data, index) => (
					<CardElement key={data.id} data={data} {...specialProps} />
				))}
			</div>
		</div>
	);
};
