/**
 * Returns a React component that renders a checkmark in the form of a circle.
 *
 * @return {JSX.Element} The SVG component representing a checkmark in a circle.
 */
export const CheckCircle = ({ width = 24, height = 24 }) => {
	return (
		<svg
			width={width}
			height={height}
			style={{ minHeight: height, minWidth: width }}
			fill='none'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.668 9.232a.75.75 0 0 1-.025 1.06l-5.5 5.25a.75.75 0 0 1-1.036 0l-2.75-2.624a.75.75 0 0 1 1.036-1.085l2.232 2.13 4.982-4.756a.75.75 0 0 1 1.06.025Z'
				fill='#4DD294'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Z'
				fill='#4DD294'
			/>
		</svg>
	);
};
