import React, { useEffect, useRef, useState } from 'react';

/**
 * Custom hook to handle responsive text.
 * @param {Object} param0 - The parameter object
 * @param {string} param0.string - The text string
 * @param {number} param0.charPerPixel - The characters per pixel ratio
 * @returns {Object} - Object containing visibleString and containerRef
 */
const useResponsiveText = ({ string, charPerPixel = 0.11, shouldUseResponsiveText = true }) => {
	const [visibleString, setVisibleString] = useState(string);
	const containerRef = useRef(null);

	/**
	 * Update the visible string based on container width and character per pixel ratio
	 */
	useEffect(() => {
		if (!shouldUseResponsiveText) {
			setVisibleString(string);
			return;
		}
		const updateVisibleString = () => {
			const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
			const maxChars = Math.floor(containerWidth * charPerPixel);

			setVisibleString(string?.slice(0, maxChars) + (string?.length > maxChars ? '...' : ''));
		};

		updateVisibleString();

		window.addEventListener('resize', updateVisibleString);

		return () => {
			window.removeEventListener('resize', updateVisibleString);
		};
	}, [string, charPerPixel, shouldUseResponsiveText]);

	return { visibleString, containerRef };
};

export default useResponsiveText;
