// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhisperAmount_whisperAmount__T3zHv {

    position: relative;

    display: flex;

    height: 3rem;

    border-radius: 0.75rem;

    padding: 0.75rem;

    padding-left: 1.5rem
}

.WhisperAmount_whisperAmountText__41SpT {

    font-family: Inter, sans-serif;

    font-size: 1.125rem;

    line-height: 1.75rem;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/WhisperAmount/WhisperAmount.module.css"],"names":[],"mappings":"AACC;;IAAA,kBAA6C;;IAA7C,aAA6C;;IAA7C,YAA6C;;IAA7C,sBAA6C;;IAA7C,gBAA6C;;IAA7C;AAA6C;;AAI7C;;IAAA,8BAA+C;;IAA/C,mBAA+C;;IAA/C,oBAA+C;;IAA/C,gBAA+C;;IAA/C,oBAA+C;;IAA/C;AAA+C","sourcesContent":[".whisperAmount {\n\t@apply flex h-12 rounded-xl p-3 pl-6 relative;\n}\n\n.whisperAmountText {\n\t@apply text-lg text-white font-sans font-normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whisperAmount": `WhisperAmount_whisperAmount__T3zHv`,
	"whisperAmountText": `WhisperAmount_whisperAmountText__41SpT`
};
export default ___CSS_LOADER_EXPORT___;
