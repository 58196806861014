import React from 'react';
import { Modal } from '../../../ui/Modal';
import styles from '../Challenges.module.css';
import { ChallengesSocialAppIcon } from '../ChallengesSocialAppIcon';
import { CHALLENGES_CONFIG } from '../ChallengesConfig';
import { Button } from '../../../ui/Button';
import { ChallengesRewards } from '../ChellengesRewards';
import { useTranslation } from 'react-i18next';

/**
 * Represents the Challenges Task Modal component.
 * @param {boolean} modalIsOpen - Flag indicating if the modal is open.
 * @param {object} currentTask - Object containing information about the current task.
 * @param {function} closeModal - Function to close the modal.
 */
export const ChallengesTaskModal = ({ modalIsOpen = false, currentTask, closeModal = () => {} }) => {
	const { t } = useTranslation();
	// Destructuring values from CHALLENGES_CONFIG and currentTask
	const { MODAL_IMG_SIZE } = CHALLENGES_CONFIG;
	const { exercise = '', appId = '', rewardsAmount = null, rewardsType = '' } = currentTask;

	return (
		<Modal isOpen={modalIsOpen} onClose={closeModal} customStyles={styles.challengesTaskModal}>
			<div className={styles.challengesTaskModalInfo}>
				{/* Render ChallengesSocialAppIcon with specified imageSize and appId */}
				<ChallengesSocialAppIcon imageSize={MODAL_IMG_SIZE} appId={appId} />
				{/* Display the exercise */}
				<h3 className={styles.challengesTaskModalExercise}>{t(exercise)}</h3>
				{/* Button to join channel */}
				<Button styleClasses='py-1.5 px-6'>{t('challenges.tasks.actionModal.joinChannelLabel')}</Button>
			</div>
			{/* Component to display rewards */}
			<ChallengesRewards
				rewardsAmount={rewardsAmount}
				rewardsType={rewardsType}
				iconSize={40}
				amountStyleClasses={'text-white text-4xl font-bold gap-2'}
			/>
			{/* Button to check task */}
			<button className={styles.challengesModalCheckButton}>{t('challenges.tasks.actionModal.checkTaskLabel')}</button>
		</Modal>
	);
};
