import React, { useEffect, useRef, useState } from 'react';
import { Toggle } from '../../../ui/Toggle';
import { DarkField } from '../../../ui/DarkField';
import styles from './NewWhisperField.module.css';
import { useAutosizeTextArea } from '../../../../hooks/useAutosizeTextArea/useAutosizeTextArea';
import { useTranslation } from 'react-i18next';

/**
 * Renders a new whisper field component.
 *
 * @return {JSX.Element} The rendered new whisper field component.
 */
export const NewWhisperField = ({ whisperFieldContainerRef, fieldValues, setFieldValues }) => {
	const [charactersCount, setCharactersCount] = useState(0);
	const textAreaRef = useRef(null);
	const maxLines = 10; // Set the maximum number of lines
	const { value, isAnonymous } = fieldValues;
	const { t } = useTranslation();

	const adjustScrollOnFocus = () => {
		if (whisperFieldContainerRef.current) {
			whisperFieldContainerRef.current.scrollIntoView({
				block: 'center',
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		const textArea = textAreaRef.current;

		const handleFocus = () => {
			adjustScrollOnFocus();
		};

		textArea.addEventListener('focus', handleFocus);

		return () => {
			textArea.removeEventListener('focus', handleFocus);
		};
	}, []);

	// Function to count the number of visible lines in the textarea
	const countVisibleLines = textArea => {
		const lineHeight = parseInt(window.getComputedStyle(textArea).lineHeight);
		const currentHeight = textArea.scrollHeight;
		return Math.floor(currentHeight / lineHeight);
	};

	// Function to adjust the textarea height based on the number of visible lines
	useEffect(() => {
		useAutosizeTextArea(textAreaRef.current, maxLines);
	}, [textAreaRef.current, value]);

	const handleInputChange = event => {
		const inputValue = event.target.value;
		const numberOfVisibleLines = countVisibleLines(event.target);

		// Allow input only if the number of visible lines is less than or equal to maxLines
		if (numberOfVisibleLines <= maxLines) {
			setFieldValues(prev => ({
				...prev,
				whisper: inputValue
			}));
			setCharactersCount(inputValue.length);
		} else {
			// Prevent input if the max number of lines is reached
			event.preventDefault();
		}
	};

	// Prevent adding more lines if maxLines is reached
	const handleKeyDown = event => {
		const numberOfVisibleLines = countVisibleLines(event.target);

		// Prevent adding a new line if max lines are reached
		if (event.key === 'Enter' && numberOfVisibleLines >= maxLines) {
			event.preventDefault();
		}
	};

	const handleToggleChange = value => {
		setFieldValues(prev => ({
			...prev,
			isAnonymous: value
		}));
	};

	return (
		<DarkField id="newWhisperField" styleClasses={styles.newWhisperFieldContainer}>
			<textarea
				placeholder={t('newWhispers-page.forms.messageInput.placeholder')}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown} // Prevent enter if max lines reached
				value={value}
				maxLength="280"
				rows={3} // Minimum rows
				ref={textAreaRef}
				className={styles.newWhisperFieldTextarea}
				style={{ overflow: 'hidden' }} // Prevent scrolling
			></textarea>
			<div className={styles.newWhisperFieldToggle}>
				<Toggle isActive={isAnonymous} setIsActive={handleToggleChange}
								text={t('newWhispers-page.forms.messageInput.toggleText')} />
				<div className={styles.newWhisperFieldCharacters}>{charactersCount}/280</div>
			</div>
		</DarkField>
	);
};
