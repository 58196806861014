import React from 'react';
/**
 * Represents the Telegram object.
 */
const tg = window.Telegram.WebApp;

/**
 * Represents the UseTelegram hook.
 * @returns {Object} An object containing tg, user, and onClose function.
 */
const UseTelegram = () => {

    /**
     * Closes the Telegram object.
     */
    const onClose = () => {
        tg.close();
    }

    return {
        tg,
        user: tg.initDataUnsafe?.user,
        onClose
    }
};

export default UseTelegram;