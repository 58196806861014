// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewWhisperEnergyBar_newWhisperEnergyContainer__IkHq7 {

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: space-between
}

.NewWhisperEnergyBar_newWhisperEnergyInformation__cPl6U {

    margin-left: 1.5rem;

    display: flex;

    align-items: center;

    gap: 0.125rem;

    font-family: Inter, sans-serif;

    font-size: 1.0625rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.NewWhisperEnergyBar_newWhisperEnergyInformation__cPl6U span {

    font-family: Inter, sans-serif;

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(62 70 105 / var(--tw-text-opacity))
}

.NewWhisperEnergyBar_newWhisperEnergyButton__mz6re {

    display: flex;

    height: 3rem;

    width: 10.875rem;

    align-items: center;

    justify-content: center;

    gap: 0.5rem;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.NewWhisperEnergyBar_newWhisperEnergyButton__mz6re:disabled {

    --tw-bg-opacity: 1;

    background-color: rgb(62 70 105 / var(--tw-bg-opacity));

    background-image: none;

    --tw-text-opacity: 1;

    color: rgb(154 162 197 / var(--tw-text-opacity))
}

.NewWhisperEnergyBar_newWhisperEnergyButtonPrice__hzbbq {

    display: flex;

    align-items: center;

    gap: 0.125rem
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/SendNewWhisper/NewWhisperEnergyBar/NewWhisperEnergyBar.module.css"],"names":[],"mappings":"AACC;;IAAA,aAA+C;;IAA/C,WAA+C;;IAA/C,mBAA+C;;IAA/C;AAA+C;;AAI/C;;IAAA,mBAA+E;;IAA/E,aAA+E;;IAA/E,mBAA+E;;IAA/E,aAA+E;;IAA/E,8BAA+E;;IAA/E,oBAA+E;;IAA/E,gBAA+E;;IAA/E,oBAA+E;;IAA/E;AAA+E;;AAI/E;;IAAA,8BAA+C;;IAA/C,kBAA+C;;IAA/C,iBAA+C;;IAA/C,gBAA+C;;IAA/C,oBAA+C;;IAA/C;AAA+C;;AAI/C;;IAAA,aAAwJ;;IAAxJ,YAAwJ;;IAAxJ,gBAAwJ;;IAAxJ,mBAAwJ;;IAAxJ,uBAAwJ;;IAAxJ,WAAwJ;;IAAxJ,8BAAwJ;;IAAxJ,mBAAwJ;;IAAxJ,gBAAwJ;;IAAxJ,oBAAwJ;;IAAxJ;AAAwJ;;AAAxJ;;IAAA,kBAAwJ;;IAAxJ,uDAAwJ;;IAAxJ,sBAAwJ;;IAAxJ,oBAAwJ;;IAAxJ;AAAwJ;;AAIxJ;;IAAA,aAAgC;;IAAhC,mBAAgC;;IAAhC;AAAgC","sourcesContent":[".newWhisperEnergyContainer {\n\t@apply flex items-center justify-between w-full;\n}\n\n.newWhisperEnergyInformation {\n\t@apply flex items-center gap-0.5 font-sans font-medium text-white text-3sm ml-6;\n}\n\n.newWhisperEnergyInformation span {\n\t@apply font-sans font-medium text-grey2 text-xs;\n}\n\n.newWhisperEnergyButton {\n\t@apply flex w-42 h-12 justify-center font-sans font-medium text-white text-2sm gap-2 items-center disabled:bg-none disabled:bg-grey2 disabled:text-grey1;\n}\n\n.newWhisperEnergyButtonPrice {\n\t@apply flex items-center gap-0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newWhisperEnergyContainer": `NewWhisperEnergyBar_newWhisperEnergyContainer__IkHq7`,
	"newWhisperEnergyInformation": `NewWhisperEnergyBar_newWhisperEnergyInformation__cPl6U`,
	"newWhisperEnergyButton": `NewWhisperEnergyBar_newWhisperEnergyButton__mz6re`,
	"newWhisperEnergyButtonPrice": `NewWhisperEnergyBar_newWhisperEnergyButtonPrice__hzbbq`
};
export default ___CSS_LOADER_EXPORT___;
