// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SegmentsController_segmentsControllerContainer__7Mfvp {
    position: sticky;
    top: 0.5rem;
    z-index: 20;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    width: fit-content;
    gap: 0.25rem;
    border-radius: 0.75rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(132 152 251 / var(--tw-border-opacity));
    background-image: linear-gradient(0deg, rgba(16, 8, 29, 0.64), rgba(16, 8, 29, 0.64)),linear-gradient(104.04deg, rgba(61, 103, 241, 0.32) -8.88%, rgba(61, 103, 241, 0) 57%);
    padding: 0.25rem;
    --tw-backdrop-blur: blur(24px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.SegmentsController_segmentsButtonItem__MoF90 {
    height: 2.375rem;
    width: 7.5rem;
    border-radius: 0.5rem;
    background: transparent
}

.SegmentsController_segmentsButtonItemActive__Bwymo {
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    background: var(--color-lightBlue)
}

.SegmentsController_segmentsButtonItemText__VGQDK {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    font-size: 1.0625rem;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(154 162 197 / var(--tw-text-opacity))
}

.SegmentsController_segmentsButtonItemTextActive__Gvj\\+- {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity))
}

.SegmentsController_segmentsButtonItemInActive__MOV65:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/SegmentsController/SegmentsController.module.css"],"names":[],"mappings":"AACI;IAAA,gBAAsJ;IAAtJ,WAAsJ;IAAtJ,WAAsJ;IAAtJ,iBAAsJ;IAAtJ,kBAAsJ;IAAtJ,eAAsJ;IAAtJ,kBAAsJ;IAAtJ,aAAsJ;IAAtJ,kBAAsJ;IAAtJ,YAAsJ;IAAtJ,sBAAsJ;IAAtJ,iBAAsJ;IAAtJ,mBAAsJ;IAAtJ,sBAAsJ;IAAtJ,yDAAsJ;IAAtJ,4KAAsJ;IAAtJ,gBAAsJ;IAAtJ,8BAAsJ;IAAtJ,+QAAsJ;IAAtJ;AAAsJ;;AAItJ;IAAA,gBAA4B;IAA5B,aAA4B;IAA5B,qBAA4B;IAC5B;AAD4B;;AAK5B;IAAA,wBAA8C;IAA9C,0BAA8C;IAA9C,wDAA8C;IAC9C;AAD8C;;AAK9C;IAAA,aAAiF;IAAjF,mBAAiF;IAAjF,uBAAiF;IAAjF,8BAAiF;IAAjF,oBAAiF;IAAjF,gBAAiF;IAAjF,oBAAiF;IAAjF;AAAiF;;AAIjF;IAAA,oBAAiB;IAAjB;AAAiB;;AAIjB;IAAA,oBAAsB;IAAtB;AAAsB","sourcesContent":[".segmentsControllerContainer {\n    @apply sticky top-2 mx-auto my-0 w-fit flex gap-1 p-1 border border-solid border-lightBlue rounded-xl bg-whispercard-bg-gradient backdrop-blur-xl z-20;\n}\n\n.segmentsButtonItem {\n    @apply h-38 w-120 rounded-lg;\n    background: transparent;\n}\n\n.segmentsButtonItemActive {\n    @apply transition-all duration-300 ease-in-out;\n    background: var(--color-lightBlue);\n}\n\n.segmentsButtonItemText {\n    @apply text-grey1 text-3sm font-sans font-medium flex items-center justify-center;\n}\n\n.segmentsButtonItemTextActive {\n    @apply text-black;\n}\n\n.segmentsButtonItemInActive {\n    @apply hover:text-white\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"segmentsControllerContainer": `SegmentsController_segmentsControllerContainer__7Mfvp`,
	"segmentsButtonItem": `SegmentsController_segmentsButtonItem__MoF90`,
	"segmentsButtonItemActive": `SegmentsController_segmentsButtonItemActive__Bwymo`,
	"segmentsButtonItemText": `SegmentsController_segmentsButtonItemText__VGQDK`,
	"segmentsButtonItemTextActive": `SegmentsController_segmentsButtonItemTextActive__Gvj+-`,
	"segmentsButtonItemInActive": `SegmentsController_segmentsButtonItemInActive__MOV65`
};
export default ___CSS_LOADER_EXPORT___;
