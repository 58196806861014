/**
 * Sets the height of a textarea element to automatically adjust based on its content.
 *
 * @param {HTMLTextAreaElement} textAreaRef - The reference to the textarea element.
 * @param {number} [maxLines=10] - The maximum number of lines the textarea can have.
 * @return {void} This function does not return anything.
 */
export const useAutosizeTextArea = (textAreaRef, maxLines = 10) => {
	if (textAreaRef) {
		// Reset the height to calculate new scrollHeight
		textAreaRef.style.height = 'auto';

		// Get the scrollHeight and limit the height to a max of 10 rows
		const scrollHeight = textAreaRef.scrollHeight;
		const lineHeight = parseInt(window.getComputedStyle(textAreaRef).lineHeight);
		const maxHeight = lineHeight * maxLines;

		// Set the new height or cap at 10 rows
		textAreaRef.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
	}
};