import React from 'react';
import styles from '../ChallengesCard.module.css';
import { ENERGY_KEY, WHISPER_COIN_KEY } from '../../../../../utils/constants';
import { EnergyIcon } from '../../../../../assets/svg/EnergyIcon';
import WhisperCoinIcon from '../../../../../assets/svg/WhisperCoinIcon';
import { ChallengesRewards } from '../../ChellengesRewards';
import { useTranslation } from 'react-i18next';

/**
 * Component for displaying information about a challenge card.
 * @param {object} data - The data object containing rewardsType, rewardsAmount, and exercise.
 * @returns JSX Element
 */
export const ChallengesCardInfo = ({ data }) => {
	const { rewardsType, rewardsAmount, exercise } = data;
	const { t } = useTranslation();

	return (
		<div className={styles.challengesCardInfo}>
			{/* Display the exercise */}
			<p className={styles.challengeCardLabel}>{t(exercise)}</p>
			{/* Component to display rewards */}
			<ChallengesRewards
				rewardsType={rewardsType}
				rewardsAmount={rewardsAmount}
				amountStyleClasses={'text-grey1 gap-0.5'}
			/>
		</div>
	);
};
