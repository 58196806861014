import React, { useEffect, useRef, useState } from 'react';
import styles from './Friends.module.css';
import { GeneralTitle } from '../../ui/GeneralTitle';
import { SegmentsController } from '../../ui/SegmentsController';
import { CopyButtonsBlock } from '../../ui/CopyButtonsBlock';
import { FriendsDescriptionList } from './FriendsDescriptionList';
import { FRIENDS_COPY_BUTTONS_CONFIG, INVITED_FRIENDS_DATA, SEGMENTS_CONTROLLER_CONFIG } from './FriendsConfig';
import { EmptyPage } from '../../ui/EmptyPage';
import { CardList } from '../../ui/CardList';
import { useTranslation } from 'react-i18next';
import { getFriendList } from '../../../utils/api';
import { Spinner } from '../../../assets/svg/Spinner';

/**
 * Renders the Friends component.
 *
 * @return {JSX.Element} The Friends component.
 */

export const Friends = () => {
	const [activeTab, setActiveTab] = useState('referrals');
	const friendsRef = useRef(null);
	const [offset, setOffset] = useState(1);
	const [fetching, setFetching] = useState(true);
	const { t } = useTranslation();
	const [friendsData, setFriendsData] = useState([]);
	const [hasMore, setHasMore] = useState(false);

	const updateInvitedFriendsList = (newFriends) => {
		INVITED_FRIENDS_DATA.lists[0].items = [...INVITED_FRIENDS_DATA.lists[0].items, ...newFriends];
	};

	useEffect(() => {
		const getFriends = async () => {
			const data = await getFriendList(8, offset);

			if (data) {
				setFriendsData([...friendsData, ...data]);
				setOffset(prevState => prevState + 1);
				updateInvitedFriendsList(data);
				setHasMore(data.length > 0);
				setFetching(false);
			} else if (data.length === 0) {
				setFetching(false);
			}
		};
		if (fetching) {
			getFriends();
		}

	}, [fetching]);

	// Обработчик скролла для friendsRef
	useEffect(() => {
		const handleScroll = () => {
			const container = friendsRef.current;
			console.log(`scrollHeight: ${container.scrollHeight}`);
			console.log(`scrollTop: ${container.scrollTop}`);
			console.log(`clientHeight: ${container.clientHeight}`);
			// Проверка на достижение конца контейнера
			if (container.scrollHeight - (container.scrollTop + container.clientHeight) < 100) {
				console.log('Near the bottom, fetching more data...');

				if (!fetching && hasMore) {
					setFetching(true);
				}
			}
		};

		const container = friendsRef.current;
		console.log(container);
		if (container) {
			console.log('da');
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [fetching]);

	useEffect(() => {
		console.log(INVITED_FRIENDS_DATA);
	}, [friendsData]);

	return (
		<div className={styles.friendsContainer} ref={friendsRef} style={{ overflow: 'auto' }}>
			<SegmentsController config={SEGMENTS_CONTROLLER_CONFIG} setActiveTab={setActiveTab} containerRef={friendsRef} />
			{
				activeTab === 'referrals' ?
					<>
						<GeneralTitle styleClasses={styles.friendsGeneralTitle}>{t('friends-page.tabs.referrals.title.title_first')}<span
							className={styles.friendsTitle}>{t('friends-page.tabs.referrals.title.title_second')}<span>{t('friends-page.tabs.referrals.title.title_third')}</span></span></GeneralTitle>
						<div className={styles.friendsContent}>
							<CopyButtonsBlock buttonsConfig={FRIENDS_COPY_BUTTONS_CONFIG} />
							<hr className={styles.friendsContentDivider} />
							<FriendsDescriptionList />
						</div>
					</>
					: (
						// Check if lists exist and map over them
						INVITED_FRIENDS_DATA.lists && INVITED_FRIENDS_DATA.lists.length > 0 ? (
							<>
								<div className={styles.friendsListContainer}>
									{INVITED_FRIENDS_DATA.lists.map(({ title, items }, index) => (
										<CardList
											key={index}
											list={items}
											CardElement={INVITED_FRIENDS_DATA.CardElement}
											title={title}
										/>
									))}
								</div>
								{fetching &&
									<div style={{ position: 'absolute', bottom: '10px' }}>
										<Spinner className="spinner-loader" style={{ background: 'red' }} />
									</div>
								}
							</>
						) : (
							// If no friends, show empty page
							<EmptyPage>
								<span className={styles.friendsContentEmptyPageText}>{t('friends-page.tabs.friends.emptyList')}</span>
							</EmptyPage>
						)
					)
			}
		</div>
	);
};
