// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FriendCard_friendCardContainer__P2EjU {

    display: flex;

    width: 100%;

    flex-direction: row;

    align-items: center;

    justify-content: space-between;

    padding: 1rem
}

@media (min-width: 390px) {

    .FriendCard_friendCardContainer__P2EjU {

        padding: 1.5rem
    }
}

.FriendCard_friendCardUserInfo__wwFcP {

    display: flex;

    flex: 1 1;

    align-items: center;

    overflow: hidden;

    white-space: normal
}

.FriendCard_friendCardUserImage__hIwEx {

    height: 2.5rem;

    width: 2.5rem;

    border-radius: 0.5rem;

    object-fit: cover
}

.FriendCard_friendCardUsername__t5\\+Tz {

    margin-left: 0.75rem;

    overflow: hidden;

    text-overflow: ellipsis;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.FriendCard_friendCardReward__V2VYR {

    display: flex;

    flex-shrink: 0;

    align-items: center;

    gap: 0.125rem
}

.FriendCard_friendCardReward__V2VYR span {

    display: flex;

    align-items: center;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Friends/FriendCard/FriendCard.module.css"],"names":[],"mappings":"AACC;;IAAA,aAAmE;;IAAnE,WAAmE;;IAAnE,mBAAmE;;IAAnE,mBAAmE;;IAAnE,8BAAmE;;IAAnE;AAAmE;;AAAnE;;IAAA;;QAAA;IAAmE;AAAA;;AAInE;;IAAA,aAAiE;;IAAjE,SAAiE;;IAAjE,mBAAiE;;IAAjE,gBAAiE;;IAAjE;AAAiE;;AAIjE;;IAAA,cAAwC;;IAAxC,aAAwC;;IAAxC,qBAAwC;;IAAxC;AAAwC;;AAIxC;;IAAA,oBAAuF;;IAAvF,gBAAuF;;IAAvF,uBAAuF;;IAAvF,8BAAuF;;IAAvF,mBAAuF;;IAAvF,gBAAuF;;IAAvF,oBAAuF;;IAAvF;AAAuF;;AAIvF;;IAAA,aAA8C;;IAA9C,cAA8C;;IAA9C,mBAA8C;;IAA9C;AAA8C;;AAI9C;;IAAA,aAAkE;;IAAlE,mBAAkE;;IAAlE,8BAAkE;;IAAlE,mBAAkE;;IAAlE,gBAAkE;;IAAlE,oBAAkE;;IAAlE;AAAkE","sourcesContent":[".friendCardContainer {\n\t@apply flex-row p-4 lg:p-6 justify-between items-center w-full flex;\n}\n\n.friendCardUserInfo {\n\t@apply flex items-center flex-1 whitespace-normal overflow-hidden;\n}\n\n.friendCardUserImage {\n\t@apply object-cover w-10 h-10 rounded-lg;\n}\n\n.friendCardUsername {\n\t@apply font-sans font-medium text-2sm text-white ml-3 overflow-ellipsis overflow-hidden;\n}\n\n.friendCardReward {\n\t@apply flex items-center gap-0.5 flex-shrink-0;\n}\n\n.friendCardReward span {\n\t@apply flex items-center text-white font-sans font-medium text-2sm;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"friendCardContainer": `FriendCard_friendCardContainer__P2EjU`,
	"friendCardUserInfo": `FriendCard_friendCardUserInfo__wwFcP`,
	"friendCardUserImage": `FriendCard_friendCardUserImage__hIwEx`,
	"friendCardUsername": `FriendCard_friendCardUsername__t5+Tz`,
	"friendCardReward": `FriendCard_friendCardReward__V2VYR`
};
export default ___CSS_LOADER_EXPORT___;
