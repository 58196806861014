// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modalOverlay__HHfRt {

    position: fixed;

    top: 0px;

    left: 0px;

    z-index: 50;

    display: flex;

    height: 100%;

    width: 100%;

    align-items: center;

    justify-content: center;

    background-color: rgba(11, 7, 16, 0.8)
}

.Modal_modal__Inl4q {
}

.Modal_modalCloseButton__gYQ9w {

    position: absolute;

    top: 1rem;

    right: 1rem
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Modal/Modal.module.css"],"names":[],"mappings":"AACC;;IAAA,eAAwF;;IAAxF,QAAwF;;IAAxF,SAAwF;;IAAxF,WAAwF;;IAAxF,aAAwF;;IAAxF,YAAwF;;IAAxF,WAAwF;;IAAxF,mBAAwF;;IAAxF,uBAAwF;;IAAxF;AAAwF;;AAGzF;AACA;;AAGC;;IAAA,kBAA6B;;IAA7B,SAA6B;;IAA7B;AAA6B","sourcesContent":[".modalOverlay {\n\t@apply fixed top-0 left-0 w-full h-full z-50 bg-overlay flex justify-center items-center;\n}\n\n.modal {\n}\n\n.modalCloseButton {\n\t@apply absolute top-4 right-4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `Modal_modalOverlay__HHfRt`,
	"modal": `Modal_modal__Inl4q`,
	"modalCloseButton": `Modal_modalCloseButton__gYQ9w`
};
export default ___CSS_LOADER_EXPORT___;
