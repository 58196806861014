// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_menu__cb3Ga {
    pointer-events: none;
    position: sticky;
    bottom: 0px;
    z-index: 40;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(180deg, rgba(11, 7, 16, 0) 0%, #0B0710 60%);
    padding-bottom: 0.5rem;
    padding-top: 4.375rem;
}@media (min-width: 320px) {.Menu_menu__cb3Ga {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
    }
}@media (min-width: 375px) {.Menu_menu__cb3Ga {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}@media (min-width: 390px) {.Menu_menu__cb3Ga {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}@media (min-width: 430px) {.Menu_menu__cb3Ga {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }
}.Menu_menu__cb3Ga {
    position: absolute;
	bottom: 0;
}

.Menu_menuHide__m3DTO {
    display: none;
}

.Menu_menu__button__wzeFG {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
	pointer-events: all;
}

.Menu_menu__button--highlighted__Q7ard {
    gap: 0.125rem;
    overflow: hidden;
    border-radius: 1rem;
    background-color: rgba(255,255,255,1.0);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    --tw-shadow: 0px 4px 10.67px 0px rgba(79, 61, 241, 16%), 0px 10.67px 42.66px 0px rgba(79, 61, 241, 48%);;
    --tw-shadow-colored: 0px 4px 10.67px 0px var(--tw-shadow-color), 0px 10.67px 42.66px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background: linear-gradient(180deg,rgb(3.19,15.78,255) 0%,rgb(57.29,0.65,155.13) 100%);
}

.Menu_menu__button-text__zBWTZ {
    position: relative;
    width: fit-content;
    white-space: nowrap;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 0.625rem;
    font-weight: 500;
}

.Menu_menu__button-text--active__OyZHg {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Menu_menu__button-text--inactive__DrxHW {
    --tw-text-opacity: 1;
    color: rgb(62 70 105 / var(--tw-text-opacity));
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Menu/Menu.module.css"],"names":[],"mappings":"AACC;IAAA,oBAAsK;IAAtK,gBAAsK;IAAtK,WAAsK;IAAtK,WAAsK;IAAtK,aAAsK;IAAtK,WAAsK;IAAtK,mBAAsK;IAAtK,8BAAsK;IAAtK,6EAAsK;IAAtK,sBAAsK;IAAtK;AAAsK,CAAtK,2BAAA;QAAA,sBAAsK;QAAtK;IAAsK;AAAA,CAAtK,2BAAA;QAAA,qBAAsK;QAAtK;IAAsK;AAAA,CAAtK,2BAAA;QAAA,oBAAsK;QAAtK;IAAsK;AAAA,CAAtK,2BAAA;QAAA,qBAAsK;QAAtK;IAAsK;AAAA,CADvK;IAEI,kBAAkB;CACrB,SAAS;AACV;;AAGC;IAAA;AAAa;;AAIb;IAAA,aAAgD;IAAhD,sBAAgD;IAAhD,mBAAgD;IAAhD,uBAAgD;CAChD;AADgD;;AAKhD;IAAA,aAAqL;IAArL,gBAAqL;IAArL,mBAAqL;IAArL,uCAAqL;IAArL,kBAAqL;IAArL,mBAAqL;IAArL,oBAAqL;IAArL,uBAAqL;IAArL,wGAAqL;IAArL,+GAAqL;IAArL,uGAAqL;IAArL;AAAqL;;AAIrL;IAAA,kBAAkF;IAAlF,kBAAkF;IAAlF,mBAAkF;IAAlF,kBAAkF;IAAlF,8BAAkF;IAAlF,mBAAkF;IAAlF;AAAkF;;AAIlF;IAAA,oBAAiB;IAAjB;AAAiB;;AAIjB;IAAA,oBAAiB;IAAjB;AAAiB","sourcesContent":[".menu {\n\t@apply flex bg-whispercard-overlay-gradient w-full items-center  justify-between py-2 pt-18 sticky z-40 bottom-0 sx:px-3.5 md:px-5 lg:px-6 xl:px-7 pointer-events-none;\n    position: absolute;\n\tbottom: 0;\n}\n\n.menuHide {\n\t@apply hidden;\n}\n\n.menu__button {\n\t@apply flex flex-col justify-center items-center;\n\tpointer-events: all;\n}\n\n.menu__button--highlighted {\n\t@apply gap-0.5 px-4 py-3 bg-[rgba(255,255,255,1.0)] rounded-2xl overflow-hidden [background:linear-gradient(180deg,rgb(3.19,15.78,255)_0%,rgb(57.29,0.65,155.13)_100%)] shadow-button;\n}\n\n.menu__button-text {\n\t@apply relative w-fit font-sans font-medium text-xxs text-center whitespace-nowrap;\n}\n\n.menu__button-text--active {\n\t@apply text-white;\n}\n\n.menu__button-text--inactive {\n\t@apply text-grey2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `Menu_menu__cb3Ga`,
	"menuHide": `Menu_menuHide__m3DTO`,
	"menu__button": `Menu_menu__button__wzeFG`,
	"menu__button--highlighted": `Menu_menu__button--highlighted__Q7ard`,
	"menu__button-text": `Menu_menu__button-text__zBWTZ`,
	"menu__button-text--active": `Menu_menu__button-text--active__OyZHg`,
	"menu__button-text--inactive": `Menu_menu__button-text--inactive__DrxHW`
};
export default ___CSS_LOADER_EXPORT___;
