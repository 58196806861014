// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 320px) {

    .ChallengesCard_challengesCard__R4BJq {

        width: 20rem
    }
}

@media (max-width: 320px) {

    .ChallengesCard_challengesCard__R4BJq {

        width: 18rem
    }
}

@media (min-width: 390px) {

    .ChallengesCard_challengesCard__R4BJq {

        width: 366px
    }
}

@media (min-width: 430px) {

    .ChallengesCard_challengesCard__R4BJq {

        width: 366px
    }
}

.ChallengesCard_challengesCardBox__\\+5rQF {

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: space-between;

    gap: 0.75rem;

    padding-top: 1.5rem;

    padding-bottom: 1.5rem;

    padding-left: 1.5rem;

    padding-right: 1rem
}

.ChallengesCard_challengesCardAppImg__OvfzP {

    min-height: 2.5rem;

    min-width: 2.5rem
}

.ChallengesCard_challengesCardInfo__NQBii {

    width: 100%;

    flex-direction: column;

    font-size: 0.938rem;

    font-weight: 500;

    line-height: 1.25rem
}

.ChallengesCard_challengeCardLabel__dGp2i {

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.ChallengesCard_challengeCardRewards__Yj4cx {

    display: flex;

    align-items: center
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Challenges/ChallengesCard/ChallengesCard.module.css"],"names":[],"mappings":"AACC;;IAAA;;QAAA;IAAkD;AAAA;;AAAlD;;IAAA;;QAAA;IAAkD;AAAA;;AAAlD;;IAAA;;QAAA;IAAkD;AAAA;;AAAlD;;IAAA;;QAAA;IAAkD;AAAA;;AAIlD;;IAAA,aAAoE;;IAApE,WAAoE;;IAApE,mBAAoE;;IAApE,8BAAoE;;IAApE,YAAoE;;IAApE,mBAAoE;;IAApE,sBAAoE;;IAApE,oBAAoE;;IAApE;AAAoE;;AAIpE;;IAAA,kBAAwB;;IAAxB;AAAwB;;AAIxB;;IAAA,WAAqD;;IAArD,sBAAqD;;IAArD,mBAAqD;;IAArD,gBAAqD;;IAArD;AAAqD;;AAIrD;;IAAA,oBAAiB;;IAAjB;AAAiB;;AAIjB;;IAAA,aAAwB;;IAAxB;AAAwB","sourcesContent":[".challengesCard {\n\t@apply m-sx:w-72 sx:w-80 lg:w-[366px] xl:w-[366px];\n}\n\n.challengesCardBox {\n\t@apply gap-3 py-6 pl-6 pr-4 flex justify-between w-full items-center;\n}\n\n.challengesCardAppImg {\n\t@apply min-w-10 min-h-10;\n}\n\n.challengesCardInfo {\n\t@apply w-full flex-col font-medium text-2sm leading-5;\n}\n\n.challengeCardLabel {\n\t@apply text-white;\n}\n\n.challengeCardRewards {\n\t@apply flex items-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"challengesCard": `ChallengesCard_challengesCard__R4BJq`,
	"challengesCardBox": `ChallengesCard_challengesCardBox__+5rQF`,
	"challengesCardAppImg": `ChallengesCard_challengesCardAppImg__OvfzP`,
	"challengesCardInfo": `ChallengesCard_challengesCardInfo__NQBii`,
	"challengeCardLabel": `ChallengesCard_challengeCardLabel__dGp2i`,
	"challengeCardRewards": `ChallengesCard_challengeCardRewards__Yj4cx`
};
export default ___CSS_LOADER_EXPORT___;
