// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GradientComponent_gradient__0pnZF {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	height: 100%;
	width: 100%;
	border-radius: 0.75rem;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	--tw-content: none;
	content: var(--tw-content);
	mask: var(--gradient-component-mask);
	mask-composite: exclude;
	background: var(--gradient-component-background)
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/GradientComponent/GradientComponent.module.css"],"names":[],"mappings":"AACC;CAAA,kBAAgH;CAAhH,SAAgH;CAAhH,UAAgH;CAAhH,QAAgH;CAAhH,WAAgH;CAAhH,YAAgH;CAAhH,WAAgH;CAAhH,sBAAgH;CAAhH,iBAAgH;CAAhH,mBAAgH;CAAhH,yBAAgH;CAAhH,kBAAgH;CAAhH,0BAAgH;CAChH,oCAAoC;CAEpC,uBAAuB;CACvB;AAJgH","sourcesContent":[".gradient {\n\t@apply w-full h-full border content-none border-solid border-transparent rounded-xl absolute inset-x-0 inset-y-0;\n\tmask: var(--gradient-component-mask);\n\t-webkit-mask-composite: destination-out;\n\tmask-composite: exclude;\n\tbackground: var(--gradient-component-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gradient": `GradientComponent_gradient__0pnZF`
};
export default ___CSS_LOADER_EXPORT___;
