import React from 'react';
import styles from './AskMe.module.css';
import { CopyButtonsBlock } from '../../ui/CopyButtonsBlock';
import { GeneralTitle } from '../../ui/GeneralTitle';
import { ASK_ME_COPY_BUTTONS_CONFIG } from './AskMeConfig';
import { useTranslation } from 'react-i18next';

export const AskMe = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.askMeContainer}>
			<GeneralTitle>
				Want more <span className={styles.askMeTitle}>Whispers?</span>
			</GeneralTitle>
			<p className={styles.askMeDescription}>{t('askMe-page.titleDescription')}</p>
			<CopyButtonsBlock buttonsConfig={ASK_ME_COPY_BUTTONS_CONFIG} />
		</div>
	);
};
