import { createBrowserRouter } from 'react-router-dom';
import App from './components/pages/App/App';
import { ErrorPage } from './components/pages/ErrorPage';
import { Main } from './components/pages/Main';
import { Friends } from './components/pages/Friends';
import { SendNewWhisper } from './components/pages/SendNewWhisper/SendNewWhisper';
import { Challenges } from './components/pages/Challenges';
import { AskMe } from './components/pages/AskMe';

/**
 * Creates a router configuration for the application.
 * @returns {Object} The router configuration object.
 */
const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: '/',
				element: <Main />
			},
			{
				path: '/challenges',
				element: <Challenges />
			},
			{
				path: '/friends',
				element: <Friends />,
			},
			{
				path: 'create-new-whisper',
				element: <SendNewWhisper />
			},
			{
				path: 'ask-me',
				element: <AskMe />
			}
		]
	}
]);

export default router;
