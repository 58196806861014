import React from 'react';
import { FRIENDS_DESCRIPTION_CONFIG } from '../FriendsConfig';
import styles from './FriendsDescriptionList.module.css';
import { useTranslation } from 'react-i18next';

/**
 * Renders a list of descriptions for the "Friends" feature.
 *
 * @return {JSX.Element} The JSX element representing the list of descriptions.
 */

export const FriendsDescriptionList = () => {
	const { t } = useTranslation();
	return (
		<div className={styles.friendsDescriptionList}>
			<h5 className={styles.friendsDescriptionTitle}>{t('friends-page.tabs.referrals.description.title')}</h5>
			{FRIENDS_DESCRIPTION_CONFIG.map((item) => (
				<div className={styles.friendsDescriptionItem} key={item.id}>
					<span className={styles.friendsDescriptionItemNumber}>{item.id}</span>
					<span>{t(item.title)}</span>
				</div>
			))}
		</div>
	);
};