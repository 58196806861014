import { WhisperCard } from './WhisperCard';

export const MAIN_CONFIG = {
	STRINGS: {
		TITLE: username => 'main.intro.title' + `${username.toUpperCase()},`,
		SUBTITLE: unreactedCount => `${unreactedCount}` + 'main.intro.subtitle'
	},
	LIST_DEF_TYPE: {
		username: '',
		lists: [],
		CardElement: WhisperCard
	}
};

export const TEST_DATA = {
	username: 'flaksilia',
	lists: [
		{
			title: 'main.cardList.reactedTitle',
			items: [
				{
					id: 0,
					username: null,
					sendDate: '03.09.2024',
					message:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aperiam asperiores at blanditiis cumque eligendi facilis iure neque non odio officia perspiciatis possimus praesentium, totam ullam vel velit voluptatem voluptatum?\n',
					options: { isOpened: false, isCollapsed: true },
					whisperCount: '2',
					react: ''
				},
				{
					id: 1,
					username: null,
					sendDate: '03.09.2024',
					message:
						"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',\n",
					options: { isOpened: false, isCollapsed: true },
					whisperCount: '2',
					react: ''
				},
				{
					id: 2,
					username: null,
					sendDate: '03.09.2024',
					message:
						"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',\n",
					options: { isOpened: false, isCollapsed: true },
					whisperCount: '2',
					react: ''
				},
				{
					id: 3,
					username: null,
					sendDate: '03.09.2024',
					message:
						"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',\n",
					options: { isOpened: false, isCollapsed: true },
					whisperCount: '2',
					react: ''
				},
				{
					id: 4,
					username: null,
					sendDate: '03.09.2024',
					message:
						"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',\n",
					options: { isOpened: false, isCollapsed: true },
					whisperCount: '2',
					react: ''
				}
			]
		},
		{
			title: 'main.cardList.unreactedTitle',
			items: [
				{
					id: 5,
					username: null,
					sendDate: '03.09.2024',
					message:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',
					options: { isOpened: true, isCollapsed: true },
					whisperCount: '777',
					react: 'hearts-smile'
				},
				{
					id: 6,
					username: null,
					sendDate: '03.09.2024',
					message:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',
					options: { isOpened: true, isCollapsed: true },
					whisperCount: '777',
					react: 'vomit-smile'
				},
				{
					id: 7,
					username: null,
					sendDate: '03.09.2024',
					message:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',
					options: { isOpened: true, isCollapsed: true },
					whisperCount: '777',
					react: 'vomit-smile'
				},
				{
					id: 9,
					username: null,
					sendDate: '03.09.2024',
					message:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',
					options: { isOpened: true, isCollapsed: true },
					whisperCount: '777',
					react: 'vomit-smile'
				},
				{
					id: 10,
					username: null,
					sendDate: '03.09.2024',
					message:
						'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias blanditiis, eos, esse laborum numquam placeat quaerat quam, reiciendis tempora vero voluptas voluptatibus.',
					options: { isOpened: true, isCollapsed: true },
					whisperCount: '777',
					react: 'vomit-smile'
				}
			]
		}
	],
	CardElement: WhisperCard
};
