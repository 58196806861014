// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FriendsDescriptionList_friendsDescriptionTitle__ZGsdZ {

    font-family: Inter, sans-serif;

    font-size: 1.0625rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.FriendsDescriptionList_friendsDescriptionList__cwyoM {

    display: flex;

    flex-direction: column;

    gap: 1.5rem;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(154 162 197 / var(--tw-text-opacity))
}

.FriendsDescriptionList_friendsDescriptionItem__Wceso {

    display: flex;

    gap: 0.75rem;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(154 162 197 / var(--tw-text-opacity))
}

.FriendsDescriptionList_friendsDescriptionItemNumber__WDQoK {

    display: flex;

    height: 1.5rem;

    width: 1.5rem;

    flex-shrink: 0;

    justify-content: center;

    border-radius: 9999px;

    border-width: 1px;

    border-style: solid;

    --tw-border-opacity: 1;

    border-color: rgb(154 162 197 / var(--tw-border-opacity));

    text-align: center
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Friends/FriendsDescriptionList/FriendsDescriptionList.module.css"],"names":[],"mappings":"AACI;;IAAA,8BAA6C;;IAA7C,oBAA6C;;IAA7C,gBAA6C;;IAA7C,oBAA6C;;IAA7C;AAA6C;;AAI7C;;IAAA,aAAmE;;IAAnE,sBAAmE;;IAAnE,WAAmE;;IAAnE,8BAAmE;;IAAnE,mBAAmE;;IAAnE,gBAAmE;;IAAnE,oBAAmE;;IAAnE;AAAmE;;AAInE;;IAAA,aAA0D;;IAA1D,YAA0D;;IAA1D,8BAA0D;;IAA1D,mBAA0D;;IAA1D,gBAA0D;;IAA1D,oBAA0D;;IAA1D;AAA0D;;AAI1D;;IAAA,aAAyG;;IAAzG,cAAyG;;IAAzG,aAAyG;;IAAzG,cAAyG;;IAAzG,uBAAyG;;IAAzG,qBAAyG;;IAAzG,iBAAyG;;IAAzG,mBAAyG;;IAAzG,sBAAyG;;IAAzG,yDAAyG;;IAAzG;AAAyG","sourcesContent":[".friendsDescriptionTitle {\n    @apply font-sans font-bold text-3sm text-white\n}\n\n.friendsDescriptionList {\n    @apply flex flex-col font-sans font-normal text-2sm text-grey1 gap-6\n}\n\n.friendsDescriptionItem {\n    @apply flex gap-3 font-sans font-normal text-2sm text-grey1\n}\n\n.friendsDescriptionItemNumber {\n    @apply flex justify-center border-solid border border-grey1 rounded-full text-center w-6 h-6 flex-shrink-0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"friendsDescriptionTitle": `FriendsDescriptionList_friendsDescriptionTitle__ZGsdZ`,
	"friendsDescriptionList": `FriendsDescriptionList_friendsDescriptionList__cwyoM`,
	"friendsDescriptionItem": `FriendsDescriptionList_friendsDescriptionItem__Wceso`,
	"friendsDescriptionItemNumber": `FriendsDescriptionList_friendsDescriptionItemNumber__WDQoK`
};
export default ___CSS_LOADER_EXPORT___;
