import { TasksIcon } from '../../../assets/svg/TasksIcon/TasksIcon';
import { WhispersIcon } from '../../../assets/svg/WhispersIcon/WhispersIcon';
import { NewWhisperIcon } from '../../../assets/svg/NewWhisperIcon';
import { AskMeIcon } from '../../../assets/svg/AskMeIcon/AskMeIcon';
import { FriendsIcon } from '../../../assets/svg/FriendsIcon/FriendsIcon';

export const BUTTONS_CONFIG = [
	{
		name: "menu.tasks",
		icon: TasksIcon,
		redirectLink: '/challenges',
		mainButton: false
	},
	{
		name: 'menu.whispers',
		icon: WhispersIcon,
		redirectLink: '/',
		mainButton: false
	},
	{
		name: 'menu.newWhisper',
		icon: NewWhisperIcon,
		redirectLink: '/create-new-whisper',
		mainButton: true
	},
	{
		name: 'menu.askMe',
		icon: AskMeIcon,
		redirectLink: '/ask-me',
		mainButton: false
	},
	{
		name: 'menu.friends',
		icon: FriendsIcon,
		redirectLink: '/friends',
		mainButton: false
	}
];
