// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/img/background-pure.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assets/img/background-ear.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    scroll-behavior: smooth;
    height: 99%;
}

.App {
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-attachment: fixed;
    background-clip: padding-box;
    background-repeat: no-repeat;
    z-index: 1;
    height: 100vh;
}

.AppWithEarBackground {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.Content {
    flex: 1 1;
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/App/App.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAGI;IAAA,kBAAmG;IAAnG,aAAmG;IAAnG,sBAAmG;IAAnG,yDAAmG;IAAnG,sBAAmG;IAAnG,4BAAmG;IAAnG,4BAAmG;IAAnG,4BAAmG;IACnG,UAAU;IACV;AAFmG;;AAMnG;IAAA;AAA6B;;AAI7B;IAAA,SAA6B;IAA7B;AAA6B","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\nbody, html {\n    scroll-behavior: smooth;\n    height: 99%;\n}\n\n.App {\n    @apply flex flex-col relative bg-no-repeat bg-cover bg-clip-padding bg-fixed bg-pure-background-img;\n    z-index: 1;\n    height: 100vh;\n}\n\n.AppWithEarBackground {\n    @apply bg-main-background-img;\n}\n\n.Content {\n    @apply flex-1 overflow-y-auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
