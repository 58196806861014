import React from 'react';
import ReactDOM from 'react-dom';
import { Card } from '../Card';
import styles from './Modal.module.css';
import classNames from 'classnames';
import Xmark from '../../../assets/svg/Xmark';

export const Modal = ({ isOpen = false, onClose = () => {}, children = null, customStyles = '' }) => {
	if (!isOpen) return null;

	const ModalStyleClasses = classNames(styles.modal, customStyles);

	return ReactDOM.createPortal(
		<div className={styles.modalOverlay} onClick={onClose}>
			<Card customClasses={ModalStyleClasses}>
				<button className={styles.modalCloseButton} onClick={onClose}>
					<Xmark />
				</button>
				{children}
			</Card>
		</div>,
		document.body
	);
};
