import { ApiClient } from '../client/client';
import axios from 'axios';

export const getCurrentUserProfile = async () => {
	const { data } = await ApiClient.get('/api/v1/profile/');
	return data;
};

export const postAccessToken = async telegramId => {
	const data = await ApiClient.post(`/api/v1/profile/login/?telegram_id=${telegramId}`);
	return data;
};

export const getFriendList = async (limit = 20, offset) => {
	const { data } = await axios.get(`https://jsonplaceholder.typicode.com/users/1/posts?_limit=${limit}&_page=${offset}`);
	// const { data } = await ApiClient.get(`/api/v1/friends/?limit=${limit}&offset=${offset}`);
	return data;
};

export const sentNewMessageData = async (userId, messageText, isAnonymous ) => {
	const { data } = await ApiClient.get(`/api/v1/messages/send/${userId}/?text=${messageText}&is_anonymous=${isAnonymous}`);
	return data;
};

export const getUserByUsername = async username => {
	const data = await ApiClient.get(`/api/v1/profile/${username}`);
	return data;
};
