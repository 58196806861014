// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserInformation_userInformation__E-yAv {

    margin-left: 0.5rem;

    display: flex;

    flex-direction: column
}

.UserInformation_userInformationTitle__kWYLU {

    font-family: Inter, sans-serif;

    font-size: 1.25rem;

    line-height: 1.75rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.UserInformation_userInformationText__Yc6qQ {

    font-family: Inter, sans-serif;

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(154 162 197 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/UserData/UserInformation/UserInformation.module.css"],"names":[],"mappings":"AACC;;IAAA,mBAAyB;;IAAzB,aAAyB;;IAAzB;AAAyB;;AAIzB;;IAAA,8BAA6C;;IAA7C,kBAA6C;;IAA7C,oBAA6C;;IAA7C,gBAA6C;;IAA7C,oBAA6C;;IAA7C;AAA6C;;AAI7C;;IAAA,8BAA+C;;IAA/C,kBAA+C;;IAA/C,iBAA+C;;IAA/C,gBAA+C;;IAA/C,oBAA+C;;IAA/C;AAA+C","sourcesContent":[".userInformation {\n\t@apply flex flex-col ml-2;\n}\n\n.userInformationTitle {\n\t@apply text-xl text-white font-sans font-bold;\n}\n\n.userInformationText {\n\t@apply text-xs text-grey1 font-sans font-normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInformation": `UserInformation_userInformation__E-yAv`,
	"userInformationTitle": `UserInformation_userInformationTitle__kWYLU`,
	"userInformationText": `UserInformation_userInformationText__Yc6qQ`
};
export default ___CSS_LOADER_EXPORT___;
