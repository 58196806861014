const TgIcon = ({ width = 20, height = 20 }) => (
	<svg
		width={width}
		height={height}
		style={{ minWidth: width, minHeight: height }}
		viewBox='0 0 40 40'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<rect width='40' height='40' rx='8' fill='#34B6FF' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.48131 18.7889C14.9595 16.2487 19.2794 14.574 21.4408 13.7649C27.6122 11.4547 28.8945 11.0534 29.7303 11.0402C29.9142 11.0373 30.3252 11.0783 30.5914 11.2727C30.8163 11.4369 30.8781 11.6587 30.9077 11.8143C30.9373 11.97 30.9742 12.3246 30.9449 12.6017C30.6104 15.7641 29.1634 23.4386 28.4272 26.9806C28.1157 28.4794 27.5023 28.9819 26.9085 29.0311C25.618 29.138 24.6381 28.2636 23.3882 27.5262C21.4324 26.3723 20.3274 25.654 18.4289 24.528C16.2349 23.2268 17.6572 22.5116 18.9076 21.3428C19.2348 21.0369 24.9207 16.3823 25.0308 15.9601C25.0445 15.9072 25.0573 15.7104 24.9274 15.6065C24.7974 15.5025 24.6057 15.5381 24.4673 15.5663C24.2711 15.6064 21.1466 17.4651 15.0938 21.1423C14.2069 21.6904 13.4036 21.9574 12.6839 21.9434C11.8904 21.928 10.3642 21.5397 9.22954 21.2077C7.83787 20.8006 6.7318 20.5853 6.82811 19.8939C6.87828 19.5337 7.42934 19.1654 8.48131 18.7889Z'
			fill='white'
		/>
	</svg>
);

export default TgIcon;
