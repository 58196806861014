import React from 'react';
import styles from '../ChallengesCard/ChallengesCard.module.css';
import { ENERGY_KEY, WHISPER_COIN_KEY } from '../../../../utils/constants';
import { EnergyIcon } from '../../../../assets/svg/EnergyIcon';
import WhisperCoinIcon from '../../../../assets/svg/WhisperCoinIcon';
import classNames from 'classnames';

/**
 * Component for displaying rewards in challenges
 * @param {number} rewardsAmount - The amount of rewards
 * @param {string} rewardsType - The type of rewards
 * @param {string} amountStyleClasses - Additional style classes for the rewards amount
 * @param {number} iconSize - The size of the reward icon
 * @returns {JSX.Element} - ChallengesRewards component
 */
export const ChallengesRewards = ({
	rewardsAmount = 0,
	rewardsType = WHISPER_COIN_KEY,
	amountStyleClasses = '',
	iconSize = 16
}) => {
	const iconProps = { width: iconSize, height: iconSize };

	// Calculate the style classes for the rewards component
	const RewardsStyleClasses = classNames(styles.challengeCardRewards, amountStyleClasses);

	return (
		<div className={RewardsStyleClasses}>
			<span>+{rewardsAmount}</span>
			{rewardsType === ENERGY_KEY && <EnergyIcon {...iconProps} />}
			{rewardsType === WHISPER_COIN_KEY && <WhisperCoinIcon {...iconProps} />}
		</div>
	);
};
