import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BUTTONS_CONFIG } from './MenuConfig';
import styles from './Menu.module.css';
import { MenuButton } from './MenuButton/MenuButton';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import classNames from 'classnames';

/**
 * Renders a menu component with buttons based on the BUTTONS_CONFIG.
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.userAgent - The user agent of the client.
 * @return {JSX.Element} The rendered menu component.
 */
export const Menu = ({ userAgent }) => {
	const navigate = useNavigate();
	const isKeyboardOpen = useDetectKeyboardOpen();
	const [activeButton, setActiveButton] = useState('Whispers');

	const isActive = buttonName => activeButton === buttonName;
	const menuStyle = classNames(styles.menu, isKeyboardOpen && userAgent !== 'iOS' && styles.menuHide);

	return (
		<div className={menuStyle}>
			{BUTTONS_CONFIG.map(button => {
				return (
					<MenuButton
						key={button.name}
						button={button}
						isActive={isActive}
						setActiveButton={setActiveButton}
						navigate={navigate}
					/>
				);
			})}
		</div>
	);
};
