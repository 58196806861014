import React, { useEffect, useRef, useState } from 'react';
import { WhisperCardReactions } from './WhisperCardReactions';
import { WhisperCardContent } from './WhisperCardContent';
import { WhisperCardOpenButton } from './WhisperCardOpenButton';
import styles from './WhisperCard.module.css';
import classNames from 'classnames';
import { Card } from '../../../ui/Card';

/**
 * Component for rendering a WhisperCard
 * @param {Object} data - Data object containing options, react, and whisperCount
 * @param onReactClick
 */
export const WhisperCard = ({ data, onReactClick }) => {
	// State for managing the status of the WhisperCard
	const [whisperCardStatus, setWhisperCardStatus] = useState({
		isOpened: false,
		isCollapsed: true
	});

	// Reference to the WhisperCard element
	const whisperCardRef = useRef(null);

	// Effect to set initial WhisperCard status based on data options
	useEffect(() => {
		setWhisperCardStatus(data.options);
	}, []);

	/**
	 * Function to toggle the collapse state of the WhisperCard
	 * This function toggles the isCollapsed state and scrolls into view if the card is collapsed
	 */
	const toggleCardCollapse = () => {
		const timeout = setTimeout(() => {
			setWhisperCardStatus(prevStatus => ({ ...prevStatus, isCollapsed: !prevStatus.isCollapsed }));
		}, 50);

		// Scroll into view if the card is collapsed
		if (whisperCardStatus.isCollapsed) {
			whisperCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}

		// Cleanup function to clear the timeout
		return () => clearTimeout(timeout);
	};

	/**
	 * Function to handle opening the WhisperCard
	 * This function sets the isOpened state after a timeout
	 * @param {Event} e - The event object
	 */
	const handleCardOpen = e => {
		const timeout = setTimeout(() => {
			setWhisperCardStatus(prevState => ({ ...prevState, isOpened: true }));
		}, 150);

		// Cleanup function to clear the timeout
		return () => clearTimeout(timeout);
	};

	// Dynamically calculate class names based on WhisperCard status
	const whisperCardClasses = classNames(styles.whispercard, !whisperCardStatus.isCollapsed && 'z-5 max-h-whisper-card');

	// Render the WhisperCard component
	return (
		<Card customClasses={whisperCardClasses} cardRef={whisperCardRef} onClick={(props, e) => toggleCardCollapse(e)}>
			<WhisperCardContent data={data} whisperCardStatus={whisperCardStatus} />
			{!whisperCardStatus.isCollapsed && whisperCardStatus.isOpened && (
				<WhisperCardReactions cardData={data} onReactClick={onReactClick} />
			)}
			{!whisperCardStatus.isCollapsed && !whisperCardStatus.isOpened && (
				<WhisperCardOpenButton rewardCount={data.whisperCount} onClick={handleCardOpen} />
			)}
		</Card>
	);
};
