/**
 * Renders an SVG icon of Tasks.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.fill - The color to fill the icon with.
 * @return {JSX.Element} The rendered SVG icon.
 */
export const TasksIcon = ({ fill }) => {
	return (
		<svg width='36' height='36' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.5 10c0-.69.56-1.25 1.25-1.25h20a1.25 1.25 0 1 1 0 2.5h-20c-.69 0-1.25-.56-1.25-1.25ZM12.5 20c0-.69.56-1.25 1.25-1.25h19.999a1.25 1.25 0 1 1 0 2.5H13.75c-.69 0-1.25-.56-1.25-1.25ZM12.5 30c0-.69.56-1.25 1.25-1.25h19.999a1.25 1.25 0 1 1 0 2.5H13.75c-.69 0-1.25-.56-1.25-1.25Z'
				fill={fill}
			/>
			<path
				d='M6.875 11.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM6.875 21.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75ZM6.875 31.875a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75Z'
				fill={fill}
			/>
		</svg>
	);
};
