// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__0lGbK {

    position: sticky;

    top: 0px;

    z-index: 40;

    display: flex;

    height: 4rem;

    align-items: center;

    justify-content: space-between;

    padding-left: 1rem;

    padding-right: 1rem;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}

.Header_headerBlur__9VsN6 {

    border-color: transparent;

    --tw-backdrop-blur: blur(48px);

    --tw-backdrop-brightness: brightness(1);

    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);

    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.Header_headerContainer__nbwuT {

    z-index: 10;

    display: flex
}

.Header_headerUserInformation__UVrvq {

    height: 3rem;

    width: 3rem;

    border-radius: 0.75rem;

    --tw-bg-opacity: 1;

    background-color: rgb(154 162 197 / var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Header/Header.module.css"],"names":[],"mappings":"AACC;;IAAA,gBAAyE;;IAAzE,QAAyE;;IAAzE,WAAyE;;IAAzE,aAAyE;;IAAzE,YAAyE;;IAAzE,mBAAyE;;IAAzE,8BAAyE;;IAAzE,kBAAyE;;IAAzE,mBAAyE;;IAAzE,mBAAyE;;IAAzE;AAAyE;;AAIzE;;IAAA,yBAAkF;;IAAlF,8BAAkF;;IAAlF,uCAAkF;;IAAlF,+QAAkF;;IAAlF;AAAkF;;AAIlF;;IAAA,WAAgB;;IAAhB;AAAgB;;AAIhB;;IAAA,YAAoC;;IAApC,WAAoC;;IAApC,sBAAoC;;IAApC,kBAAoC;;IAApC;AAAoC","sourcesContent":[".header {\n\t@apply flex justify-between items-center px-4 py-2 h-16 sticky top-0 z-40;\n}\n\n.headerBlur {\n\t@apply backdrop-filter backdrop-blur-48 backdrop-brightness-100 border-transparent;\n}\n\n.headerContainer {\n\t@apply flex z-10;\n}\n\n.headerUserInformation {\n\t@apply w-12 h-12 rounded-xl bg-grey1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__0lGbK`,
	"headerBlur": `Header_headerBlur__9VsN6`,
	"headerContainer": `Header_headerContainer__nbwuT`,
	"headerUserInformation": `Header_headerUserInformation__UVrvq`
};
export default ___CSS_LOADER_EXPORT___;
