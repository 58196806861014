import React from 'react';

/**
 * Renders an SVG icon of Whispers.
 *
 * @param {Object} props - The properties for the component.
 * @param {string} props.fill - The color to fill the icon with.
 * @return {JSX.Element} The rendered SVG icon.
 */
export const WhispersIcon = ({ fill, width = 36, height = 36 }) => {
	return (
		<svg
			width={width}
			height={height}
			style={{ minWidth: width, minHeight: height }}
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M26.406 16.624c.836 0 1.514-.663 1.514-1.482 0-.818-.678-1.481-1.514-1.481s-1.513.663-1.513 1.481c0 .819.677 1.482 1.513 1.482Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.875 6.02A9.972 9.972 0 0 0 15.9 8.847a9.6 9.6 0 0 0-2.132 3.127A1.25 1.25 0 1 1 11.465 11a12.102 12.102 0 0 1 2.687-3.94 12.471 12.471 0 0 1 8.723-3.54c3.266 0 6.404 1.27 8.722 3.54a12.001 12.001 0 0 1 3.627 8.576V27.49a1.25 1.25 0 0 1-2.032.976l-3.357-2.69h-.637a1.25 1.25 0 1 1 0-2.5h1.076c.284 0 .56.098.781.275l1.669 1.337v-9.252a9.501 9.501 0 0 0-2.875-6.79 9.972 9.972 0 0 0-6.974-2.825Z'
				fill={fill}
			/>
			<path
				d='M18.962 25.622c-.739 0-1.338-.586-1.338-1.31 0-.723.6-1.31 1.338-1.31.74 0 1.338.587 1.338 1.31 0 .724-.599 1.31-1.338 1.31ZM12.717 25.622c-.74 0-1.338-.586-1.338-1.31 0-.723.599-1.31 1.338-1.31.74 0 1.338.587 1.338 1.31 0 .724-.599 1.31-1.338 1.31Z'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.84 16.393c2.28 0 4.46.886 6.064 2.457a8.256 8.256 0 0 1 2.499 5.9v6.61H9.297c-.284 0-.56.097-.781.274l-1.24.993V24.75c0-2.208.896-4.331 2.499-5.9a8.672 8.672 0 0 1 6.064-2.457Zm7.813.67a11.171 11.171 0 0 0-7.813-3.17 11.171 11.171 0 0 0-7.814 3.17 10.756 10.756 0 0 0-3.25 7.687v10.48a1.25 1.25 0 0 0 2.032.976l2.928-2.346h15.917c.69 0 1.25-.56 1.25-1.25v-7.86c0-2.889-1.172-5.653-3.25-7.687Z'
				fill={fill}
			/>
		</svg>
	);
};
