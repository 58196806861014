import React from 'react';

/**
 * Renders a toggle component with a label, checkbox, and a slider.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.text - The text to display next to the toggle.
 * @return {JSX.Element} The toggle component.
 */
export const Toggle = ({ text, isActive, setIsActive }) => {
	const handleToggle = () => {
		setIsActive(!isActive);
	};

	return (
		<label className="inline-flex items-center cursor-pointer">
			<input type="checkbox" value="" className="sr-only peer" checked={isActive} onChange={handleToggle} />
			<div
				className="relative w-11 h-6 bg-black dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-whispercard-reaction-box-gradient"></div>
			<span className=" pl-2 text-xs font-sans font-medium text-white">{text}</span>
		</label>
	);
};
