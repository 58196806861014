import { SOCIAL_APP_KEYS } from '../../../../utils/constants';
import InstIcon from '../../../../assets/svg/instIcon';
import TgIcon from '../../../../assets/svg/TgIcon';
import XIcon from '../../../../assets/svg/XIcon';
import React from 'react';

/**
 * Represents the Challenges Social App Icon component.
 * @param {string} appId - The ID of the social app.
 * @param {number} imageSize - The size of the image.
 * @returns {JSX.Element} The rendered social app icon.
 */
export const ChallengesSocialAppIcon = ({ appId, imageSize }) => {
	// Set the image properties
	const imgProps = { width: imageSize, height: imageSize };

	// Render different icons based on the app ID
	switch (appId) {
		case SOCIAL_APP_KEYS.INST:
			return <InstIcon {...imgProps} />;
		case SOCIAL_APP_KEYS.TG:
			return <TgIcon {...imgProps} />;
		case SOCIAL_APP_KEYS.X:
			return <XIcon {...imgProps} />;
		default:
			return null; // Return null if no matching app ID
	}
};
