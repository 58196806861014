import React from 'react';
import styles from './CopyButtonsBlock.module.css';
import { Button } from '../Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../../../assets/svg/copy-icon.svg';
import { useTranslation } from 'react-i18next';
import { getTelegramLink } from '../../../utils/getTelegramLink';
import { TELEGRAM_LINK } from '../../../utils/constants';

/**
 * Renders a component with two buttons: one for inviting a friend via Telegram and another for copying the Telegram link.
 *
 * @return {JSX.Element} The rendered component.
 */

export const CopyButtonsBlock = ({ buttonsConfig }) => {
	const { t } = useTranslation();
	const telegramLink = getTelegramLink(TELEGRAM_LINK, t(buttonsConfig.mainButton.linkText, { username: '' }));
	const textForCopy = TELEGRAM_LINK + t(buttonsConfig.mainButton.linkText, { username: '' });

	return (
		<div className={styles.buttonsContainer}>
			<Button>
				<a href={telegramLink} className={styles.buttonText}>
					{t(buttonsConfig.mainButton.title)}
				</a>
			</Button>
			<span className={styles.buttonsDivider}>or</span>
			<CopyToClipboard text={textForCopy}>
				<Button styleClasses={styles.copyButton}>
					<img src={copyIcon} alt="copy" />
				</Button>
			</CopyToClipboard>
		</div>
	);
};
