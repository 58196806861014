import React from 'react';
import styles from '../Menu.module.css';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 * Renders a menu button component.
 *
 * @param {Object} props - The properties of the component.
 * @param {Object} props.button - The button configuration.
 * @param {Function} props.isActive - The function to check if the button is active.
 * @param {Function} props.setActiveButton - The function to set the active button.
 * @return {JSX.Element} The rendered menu button.
 */
export const MenuButton = ({ button, isActive, setActiveButton }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const IconComponent = button.icon;
	const buttonTextClass = classNames(styles['menu__button-text'], {
		[styles['menu__button-text--active']]: isActive(button.name) || button.mainButton,
		[styles['menu__button-text--inactive']]: !isActive(button.name) && !button.mainButton
	});

	return (
		<button
			key={button.name}
			className={`${styles.menu__button} ${button.mainButton ? styles['menu__button--highlighted'] : ''}`}
			onClick={() => {
				navigate(`${button.redirectLink}`);
				setActiveButton(button.name);
			}}>
			<IconComponent fill={isActive(button.name) || button.mainButton ? 'white' : 'var(--color-grey2)'} />
			<p className={buttonTextClass}>{t(button.name)}</p>
		</button>
	);
};
