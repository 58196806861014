import { createContext, useEffect, useState } from 'react';
import { getCurrentUserProfile } from '../utils/api';

export const CurrentUserContext = createContext();

export const CurrentUserContextProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState({});

	useEffect(() => {
		const getUserData = async () => {
			const data = await getCurrentUserProfile();

			if (data) setCurrentUser(data);
		};

		getUserData();
	}, []);

	return <CurrentUserContext.Provider value={{ currentUser }}>{children}</CurrentUserContext.Provider>;
};
