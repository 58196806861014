// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyPage_emptyPageContainer__7gpG6 {
    display: flex;
    height: 75%;
    width: 100%;
    align-items: center;
    justify-content: center
}`, "",{"version":3,"sources":["webpack://./src/components/ui/EmptyPage/EmptyPage.module.css"],"names":[],"mappings":"AACI;IAAA,aAAoD;IAApD,WAAoD;IAApD,WAAoD;IAApD,mBAAoD;IAApD;AAAoD","sourcesContent":[".emptyPageContainer {\n    @apply flex w-full h-3/4 justify-center items-center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyPageContainer": `EmptyPage_emptyPageContainer__7gpG6`
};
export default ___CSS_LOADER_EXPORT___;
