// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AskMe_askMeContainer__7yKmj {

    margin-top: 1.5rem;

    display: flex;

    height: 100%;

    flex-direction: column;

    padding-left: 1rem;

    padding-right: 1rem
}

@media (min-width: 320px) {

    .AskMe_askMeContainer__7yKmj {

        padding-left: 1rem;

        padding-right: 1rem
    }
}

@media (min-width: 375px) {

    .AskMe_askMeContainer__7yKmj {

        padding-left: 1.25rem;

        padding-right: 1.25rem
    }
}

@media (min-width: 390px) {

    .AskMe_askMeContainer__7yKmj {

        padding-left: 1.5rem;

        padding-right: 1.5rem
    }
}

@media (min-width: 430px) {

    .AskMe_askMeContainer__7yKmj {

        padding-left: 2.25rem;

        padding-right: 2.25rem
    }
}

.AskMe_askMeTitle__gQWRK {

    display: block;

    --tw-text-opacity: 1;

    color: rgb(253 217 97 / var(--tw-text-opacity))
}

.AskMe_askMeDescription__SQL4i {

    margin-top: 1.5rem;

    margin-bottom: 2rem;

    text-align: center;

    font-family: Inter, sans-serif;

    font-size: 0.938rem;

    font-weight: 400;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}`, "",{"version":3,"sources":["webpack://./src/components/pages/AskMe/AskMe.module.css"],"names":[],"mappings":"AACI;;IAAA,kBAAqE;;IAArE,aAAqE;;IAArE,YAAqE;;IAArE,sBAAqE;;IAArE,kBAAqE;;IAArE;AAAqE;;AAArE;;IAAA;;QAAA,kBAAqE;;QAArE;IAAqE;AAAA;;AAArE;;IAAA;;QAAA,qBAAqE;;QAArE;IAAqE;AAAA;;AAArE;;IAAA;;QAAA,oBAAqE;;QAArE;IAAqE;AAAA;;AAArE;;IAAA;;QAAA,qBAAqE;;QAArE;IAAqE;AAAA;;AAIrE;;IAAA,cAA8B;;IAA9B,oBAA8B;;IAA9B;AAA8B;;AAI9B;;IAAA,kBAAqE;;IAArE,mBAAqE;;IAArE,kBAAqE;;IAArE,8BAAqE;;IAArE,mBAAqE;;IAArE,gBAAqE;;IAArE,oBAAqE;;IAArE;AAAqE","sourcesContent":[".askMeContainer {\n    @apply flex flex-col px-4 sx:px-4 md:px-5 lg:px-6 xl:px-9 h-full mt-6;\n}\n\n.askMeTitle {\n    @apply block text-yellowSpecial\n}\n\n.askMeDescription {\n    @apply text-white font-sans font-normal text-2sm mt-6 mb-8 text-center\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"askMeContainer": `AskMe_askMeContainer__7yKmj`,
	"askMeTitle": `AskMe_askMeTitle__gQWRK`,
	"askMeDescription": `AskMe_askMeDescription__SQL4i`
};
export default ___CSS_LOADER_EXPORT___;
